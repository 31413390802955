import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { colors } from '../../components/charts/Styles';

function titleCase(str) {
  str = str.replaceAll('_', ' ').toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export default function TopOfflineCategoriesChart({ response }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const data = response.map((x, index) => ({
    name: x.type,
    color: colors[index],
    category_value: parseFloat(x.total_perc),
    category_icon: x.category_icon,
    place_name: x.places[0].place,
    place_value: x.places[0].total_perc,
    place_icon: x.places[0].image_src,
    places: x.places
  }));

  const renderTooltip = function (props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0];
      const places = data.payload.places.map(a => (
        <div key={a.place}>
          <p>
            <img src={a.image_src} alt="" width="24" height="24" /> {titleCase(a.place)}
            <br />
            <span>{a.description}</span>
          </p>
        </div>
      ));

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 20,
            padding: 10,
            width: '50%'
          }}
        >
          <p>
            <b style={{ color: data.payload.color }}>{data.payload.name}</b> <br />
            <b>{numeral(data.value).format('%0,0.[00]')}</b>
          </p>
          {places}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <BarChart data={data} margin={{ top: 0, right: 40, bottom: 0, left: 20 }} layout="vertical">
        <XAxis type="number" tickFormatter={value => numeral(value).format('%0')} />
        <YAxis
          dataKey="name"
          width={150}
          type="category"
          tick={{ fontSize: 12 }}
          tickFormatter={value => titleCase(value)}
        />
        <Tooltip content={props => renderTooltip(props)} />
        <Bar dataKey="category_value" name="share" barSize={20}>
          {response.map((entry, index) => (
            <Cell fill={colors[index]} key={index} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
