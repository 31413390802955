/* eslint-disable no-nested-ternary */
import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import moment from 'moment';

import {
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  // Legend,
  Tooltip,
  ResponsiveContainer,
  // CartesianGrid,
  Scatter
} from 'recharts';
import { colors } from '../../components/charts/Styles';

export function StandardEventsWeekHourChart({ response, visits, poiType }) {
  if (!response || response.length === 0 || visits === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const responseByHour = [];
  for (let i = 1; i <= 7; i++) {
    const hours = [
      {
        hour: '6:00am - 9:59am',
        index: 1,
        value: 0,
        visits: 0
      },
      {
        hour: '10:00am - 1:59pm',
        index: 1,
        value: 0,
        visits: 0
      },
      {
        hour: '2:00pm - 5:59pm',
        index: 1,
        value: 0,
        visits: 0
      },
      {
        hour: '6:00pm - 9:59pm',
        index: 1,
        value: 0,
        visits: 0
      }
    ];
    for (let h = 1; h <= 4; h++) {
      const v = response.find(d => d.dayofweek === i && d.range === h);
      const range = v.range - 1;

      if (range > -1) {
        const currentH = hours[range];
        currentH.value += v ? v.visits / visits : 0;
        currentH.visits += v ? v.visits : 0;
        hours[range] = currentH;
      }
    }
    responseByHour.push(hours);
  }

  return BaseEventsWeekHourChart({ responseByHour, poiType });
}

export function CustomEventsWeekHourChart({ response, visits, poiType }) {
  if (
    !response ||
    response.length === 0 ||
    !response.hasValue('metricsTimeBlock') ||
    response.data.value.metricsTimeBlock.length === 0
  )
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const responseTimeBlock = response.data.value.metricsTimeBlock;

  const blockStarts = responseTimeBlock
    .map(a => a.block_time_start)
    .filter((v, i, a) => a.indexOf(v) === i);
  const blockEnds = responseTimeBlock
    .map(a => a.block_time_end)
    .filter((v, i, a) => a.indexOf(v) === i);

  // console.log(dows, blockStarts, blockEnds);
  // console.log(responseTimeBlock);

  // let visits
  const responseByHour = [];
  for (let i = 1; i <= 7; i++) {
    const hours = [];
    for (let h = 0; h < blockStarts.length; h++) {
      const v = responseTimeBlock.find(d => d.dow === i && d.block_time_start === blockStarts[h]);
      const s = moment(`2013-01-01T${blockStarts[h]}`).format('HH:mmA');
      const e = moment(`2013-01-01T${blockEnds[h]}`).format('HH:mmA');

      hours.push({
        hour: `${s} - ${e}`,
        index: 1,
        value: v ? v.visits / visits : 0,
        visits: v ? v.visits : 0
      });
    }

    responseByHour.push(hours);
  }

  return BaseEventsWeekHourChart({ responseByHour, poiType });
}

function BaseEventsWeekHourChart({ responseByHour, poiType }) {
  const parseDomain = () => {
    const maxRow = responseByHour.map(row => Math.max(...row.map(entry => entry.value)));
    const max = Math.max.apply(null, maxRow);

    const minRow = responseByHour.map(row => Math.min(...row.map(entry => entry.value)));
    const min = Math.min.apply(null, minRow);

    return [min, max];
  };

  const domain = parseDomain();

  const range = [20, 300];

  const renderTooltip = function (props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
            zIndex: 999999
          }}
        >
          <p>
            <strong>
              {payload[1].name}
              <br />
              {data.hour}
            </strong>
          </p>
          <p>
            <span>{i18n.t(poiType === 'ooh' ? 'visits_label_ooh' : 'visits_label')}: </span>
            {numeral(data.visits).format('0,0')} ({numeral(data.value).format('%0.0')})
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            name="hour"
            interval={0}
            tick={{ fontSize: 0 }}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('monday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('monday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[1]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            name="hour"
            interval={0}
            tick={{ fontSize: 0 }}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('tuesday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('tuesday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[2]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            name="hour"
            interval={0}
            tick={{ fontSize: 0 }}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('wednesday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('wednesday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[3]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            name="hour"
            interval={0}
            tick={{ fontSize: 0 }}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('thursday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('thursday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[4]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            name="hour"
            interval={0}
            tick={{ fontSize: 0 }}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('friday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('friday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[5]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            name="hour"
            interval={0}
            tick={{ fontSize: 0 }}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('saturday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('saturday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[6]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
      <ResponsiveContainer height={60}>
        <ScatterChart margin={{ top: 10, right: 0, bottom: 0, left: 30 }}>
          <XAxis
            type="category"
            dataKey="hour"
            interval={0}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            name={i18n.t('sunday')}
            height={10}
            width={80}
            tick={false}
            tickLine={false}
            axisLine={false}
            label={{ value: i18n.t('sunday'), position: 'insideRight' }}
          />
          <ZAxis
            type="number"
            dataKey="value"
            domain={domain}
            range={range}
            responseByHour={responseByHour}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={props => renderTooltip(props)}
          />
          <Scatter data={responseByHour[0]} fill={colors[2]} />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}
