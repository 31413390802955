import React from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { GeodesicLine } from 'react-leaflet-geodesic';

export function NeighborhoodMap(props) {
  const { neighborhoodData, zoom } = props;

  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom
  };

  function CurveContainer() {
    const map = useMap();
    return neighborhoodData.map((p, i) => (
      <GeodesicLine
        key={i}
        positions={[
          [parseFloat(p.positions[1][0]), parseFloat(p.positions[1][1])],
          [p.positions[3][0], p.positions[3][1]]
        ]}
        options={{ color: p.color, weight: 0.5 }}
        map={map}
      />
    ));
  }

  return (
    <MapContainer
      className="markercluster-map-full"
      center={defaultProps.center}
      zoom={defaultProps.zoom}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <CurveContainer />
    </MapContainer>
  );
}
