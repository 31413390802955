import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';
import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';
import { useDashboardData } from '../../../hooks/metrics/esdata';
import { useAPIDataLoader } from '../../../hooks/api';
import Logo from '../Logo';
import { filterSelector } from '../../../redux/ducks/filters';

export default function Neighborhood(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const { responseRadar } = useDashboardData(typeFilter, {
    qRadar: true
  });

  const [coords, setCoords] = useState([]);

  useEffect(() => {
    const stores =
      responseRadar && responseRadar.hasValue('heatmap')
        ? responseRadar.data.value.heatmap.stores
        : [];

    if (stores.length > 0) {
      setCoords(
        stores.map(store => {
          return {
            id: store._id,
            latitude: store.latitude,
            longitude: store.longitude
          };
        })
      );
    }
  }, [responseRadar]);

  const responseNeighborhoodData = useAPIDataLoader(
    'premium.getNeighborhoodData',
    { stores: coords },
    {
      cache: true
    }
  );
  if (responseNeighborhoodData.error) {
    console.log('getNeighborhoodData error', responseNeighborhoodData.error);
  }

  const filterComponent = (
    <div className="m-auto text-center small text-white">
      Powered by <br />
      <Logo brand="equifax" file="logo-premium.png" alt="Equifax" />
    </div>
  );

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('premium_neighborhood_patterns_title')}
        subtitle={i18n.t('premium_neighborhood_patterns_text')}
        filterComponent={filterComponent}
        filterComponentSize="4"
      />
      <ViewContent responseNeighborhoodData={responseNeighborhoodData} />
    </div>
  );
}
