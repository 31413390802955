import React, { useState, useRef, useMemo } from 'react';
import i18n from 'i18n-js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { Treebeard } from 'react-treebeard';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md/';
import { callSyncAPIv2DataLoader, callSyncAPIDataLoader } from '../../hooks/api';
import * as filters from './tree_filter';
import treeStyle from './treeStyle';

let searchTask;

export default function ModalFilter({
  // responseFilters,
  modal,
  setModal,
  onSelect,
  filterSinglePOI = false,
  filter,
  preventDefault = false
}) {
  // backwards compatibility
  const useApiV2 = window._env_.REACT_APP_USE_POI_TREE_NODES === 'true'; // defaults to false

  const _data = useRef(null);
  const poiType = useRef(filter.type);
  const [data, setData] = useState({});
  const [cursor, setCursor] = useState(false);
  // const [loadingNode, setLoadingNode] = useState(null);
  // const [decorators, setDecorators] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [textFilter, setTextFilter] = useState('');

  // console.log('data', data);
  let loadingNode = null;

  // clone JSON to trigger repaint
  const resetData = d => {
    setData({ ...d });
  };

  const prepareNode = node => {
    // keep backwards compatibility
    return useApiV2
      ? {
          name: node.displayName,
          group_hash: node.groupHash,
          groupCount: node.groupSize
          // month_range_start: node.month_range_start,
          // month_range_end: node.month_range_end,
          // periods: node.periods
        }
      : node;
  };

  const isSelectable = node => {
    if (useApiV2)
      return node.nodeType !== 'Folder' && (!filterSinglePOI || node.nodeType === 'ProxyGroup');

    return node.group_hash && (!filterSinglePOI || (node.groupCount === 1 && !node.children));
  };

  const loadNode = nodeId => {
    // console.log('loading node', nodeId, data);
    // setLoadingNode(nodeId);
    loadingNode = nodeId;

    callSyncAPIv2DataLoader(
      'poisTree',
      `${nodeId}/children`,
      {
        period: filter.period,
        startDate: filter.startDate.valueOf(),
        endDate: filter.endDate.valueOf(),
        offset: filter.offset,
        type: filter.type
      },
      { responseHolder: `nodes${nodeId}` },
      res => {
        // console.log('finish loading', nodeId, res);

        addChildren(nodeId, res.data.value[`nodes${nodeId}`].tree.children, data);
        // setLoadingNode(null);
        loadingNode = null;
      }
    );
  };

  const onSelectNode = node => {
    // console.log('node', node.active, node.childrenLoaded, loadingNode);
    // console.log('onSelect', data);
    // const a = node.active;
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
      resetData(data);
    }

    // if (node.children && (!isSelectable(node) || a)) {
    if (node.children && !node.toggled) {
      node.toggled = !node.toggled;
    }

    if (
      useApiV2 &&
      node.nodeType !== 'ProxyGroup' &&
      node.children.length === 0 &&
      !node.childrenLoaded
    ) {
      if (!loadingNode) {
        // is nothing is currently loading
        node.loading = true;
        node.toggled = true;
        loadNode(node.nodeId);
      }
    }

    node.active = true;
    setCursor(node);
    resetData(data);
  };

  // load root data
  useMemo(() => {
    // initializar
    if (!_data.current || poiType.current !== filter.type) {
      poiType.current = filter.type;
      // console.log('useMemo filter', _data, filter);
      if (useApiV2) {
        callSyncAPIv2DataLoader(
          'poisTree',
          `root/children`,
          {
            period: filter.period,
            startDate: filter.startDate.valueOf(),
            endDate: filter.endDate.valueOf(),
            offset: filter.offset,
            type: filter.type
          },
          { cache: true, responseHolder: `nodesroot` },
          res => {
            _data.current = prepareBasicData({
              ts: 2,
              loading: false,
              children: res.data.value.nodesroot.tree.children,
              defaultPoiTreeNode: res.data.value.nodesroot.tree.defaultPoiTreeNode,
              filter
            });

            const d = _data.current;

            if (
              d.ts !== undefined &&
              (data.ts === undefined || d.ts !== data.ts || d.type !== data.type)
            ) {
              // console.log('first time setting data', d);
              setData({ ...d });
              setTextFilter('');

              // fire default selection (if any)
              if (
                !preventDefault &&
                d.defaultPoiTreeNode &&
                (!filter.groupKeys || filter.groupKeys.length === 0)
              ) {
                onSelect(prepareNode(d.defaultPoiTreeNode));
              }
            }
          }
        );
      } else {
        callSyncAPIDataLoader(
          'pois.getDataForFilters',
          {
            period: filter.period,
            startDate: filter.startDate.valueOf(),
            endDate: filter.endDate.valueOf(),
            offset: filter.offset,
            type: filter.type
          },
          { cache: true },
          res => {
            // console.log(responseFilters);
            _data.current = JSON.parse(JSON.stringify(res.data.value.pdvs));

            const d = _data.current;

            if (
              d.ts !== undefined &&
              (data.ts === undefined || d.ts !== data.ts || d.type !== data.type)
            ) {
              // console.log('first time setting data', d);
              setData(d);
            }
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data, filter]);

  function findChildrenPath(nodeId, lvl, path) {
    // console.log('findChildrenPath', nodeId, lvl, path);
    if (!lvl.children || lvl.children.length === 0) return null;

    let res = null;

    for (let i = 0; i < lvl.children.length; i++) {
      const currentPath = `${path}.${i}`;

      if (lvl.children[i].nodeId === nodeId) return currentPath; // found
      if (lvl.children[i].children && lvl.children[i].children.length > 0) {
        res = findChildrenPath(nodeId, lvl.children[i], `${currentPath}.children`); // not found, search inside current path
        if (res != null) return res;
      }
    }

    return null;
  }

  // modifies 'data' json
  function addChildren(parentId, nodes, data) {
    // call API to get node's children, then add to data json and refresh screen
    // console.log('addChildren', parentId, nodes);

    const path = findChildrenPath(parentId, data, 'children');
    // console.log('addChildren path', path);

    if (path !== null) {
      // console.log('start TO');
      const res = path.split('.').reduce(function (o, k) {
        return o && o[k];
      }, data);

      res.children = nodes || [];
      res.loading = false;
      res.childrenLoaded = true;
      // console.log('reset', data);
      resetData(data);

      // console.log('end TO');
    }

    // setCurrentNodeId(null); // reset
  }

  const getDisplayName = node => {
    if (!node || !node.displayName) return null;

    return node.nodeType === 'Folder' || node.nodeType === 'ProxyGroup'
      ? node.displayName
      : `${node.displayName} (${node.groupSize})`;
  };

  const onToggle = (node, toggled) => {
    // console.log('toggle', node);
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
    }
    node.active = true;

    if (node.children && node.children.length > 0) {
      node.toggled = toggled;
      setCursor(node.children);
      // cursor.active = false;
    } else if (
      useApiV2 &&
      node.nodeType !== 'ProxyGroup' &&
      node.children.length === 0 &&
      !node.childrenLoaded
    ) {
      if (!loadingNode) {
        // is nothing is currently loading
        node.loading = true;
        node.toggled = true;
        loadNode(node.nodeId);
      }
    }
    setCursor(node);
    resetData(data);
  };

  const onFilterKeyUp = ({ target: { value } }) => {
    const tf = value.trim();
    // textFilter = value.trim();
    // setTextFilter(value.trim());
    if (searchTask) clearTimeout(searchTask);

    return new Promise(resolve => {
      searchTask = setTimeout(() => {
        setTextFilter(tf);
        if (!tf || tf === '') {
          return resetData(_data.current);
        }

        if (useApiV2) {
          // return resetData(data);
          if (!isSearching) {
            setIsSearching(true);
            callSyncAPIv2DataLoader(
              'poisTree',
              `upperBranches`,
              {
                filter: tf,
                period: filter.period,
                startDate: filter.startDate.valueOf(),
                endDate: filter.endDate.valueOf(),
                offset: filter.offset,
                type: filter.type
              },
              // if no nodeId then skip this call
              { skip: !filter, cache: true, responseHolder: `searchResult` },
              res => {
                setIsSearching(false);
                // console.log(res);
                if (res.hasValue('searchResult')) {
                  const d = prepareBasicData({
                    ts: 2,
                    loading: false,
                    children: res.data.value.searchResult.tree.children,
                    filter
                  });
                  // console.log('Reset', d);
                  setData({ ...d });

                  // setData(res.data.value.searchResult); // do not reset to avoid overwriting original tree (_data)
                }
              }
            );
          }
        } else {
          let filtered = filters.filterTree(_data.current, tf);
          filtered = filters.expandFilteredNodes(filtered, tf);
          return setData(filtered);
        }
        return true;
      }, 500);
    });
  };

  // useEffect(() => {
  //   setDecorators({
  const decorators = {
    // eslint-disable-next-line react/no-unstable-nested-components
    Loading: props => {
      return <div style={props.style}>loading...</div>;
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    Header: ({ onSelect, node, style, customStyles }) => (
      <div style={style.base} onClick={() => onSelect(node)}>
        <div style={node.selected ? { ...style.title, ...customStyles.header.title } : style.title}>
          {useApiV2 ? getDisplayName(node) : node.name}
        </div>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    Container: ({ style, decorators, terminal, node, customStyles, onClick }) => {
      const renderToggle = node.toggled ? (
        <MdKeyboardArrowDown
          size="18"
          color="#000"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <MdKeyboardArrowRight
          size="18"
          color="#000"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      );
      return (
        <div style={node.active && isSelectable(node) ? { ...style.container } : { ...style.link }}>
          {!terminal && node.nodeType !== 'ProxyGroup' ? renderToggle : null}
          <decorators.Header
            node={node}
            style={style.header}
            customStyles={customStyles}
            onSelect={onSelectNode}
          />
        </div>
      );
    }
    // });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, loadingNode, cursor]);

  const searchLoading = (
    <div className="loading_metric float-right position-relative" style={{ right: 0 }}>
      <div className="loading-dot" />
    </div>
  );

  return (
    <Modal size="lg" isOpen={modal} toggle={() => setModal(false)} className="modalFadeInScale">
      <ModalHeader toggle={() => setModal(false)}>
        {i18n.t(filter.type === 'ooh' ? 'admin_oohs_list' : 'admin_pois_list')}
      </ModalHeader>

      <ModalBody>
        {i18n.t('search_for')}
        <div>
          {isSearching && searchLoading}
          <Input type="text" name="groupName" onKeyUp={onFilterKeyUp} defaultValue={textFilter} />
        </div>
        <Treebeard style={treeStyle} data={data} onToggle={onToggle} decorators={decorators} />
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={!cursor.active || !isSelectable(cursor)}
          color="primary"
          onClick={() => onSelect(prepareNode(cursor))}
        >
          {i18n.t('apply_filters_button')}
        </Button>
        <Button color="secondary" onClick={() => setModal(false)}>
          {i18n.t('cancel_button')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const prepareBasicData = ({ ts, loading, children, defaultPoiTreeNode, filter }) => {
  // console.log(children);
  return {
    ts,
    displayName: 'root',
    nodeId: 'root',
    nodeType: 'Folder',
    toggled: true,
    loading,
    children: children || [],
    childrenLoaded: true,
    type: filter.type,
    defaultPoiTreeNode
  };
};
