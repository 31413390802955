import axios from 'axios';
// import { generateHash } from '../api/mofidata';
// import { refreshToken, logout, metricsCache } from '../redux/ducks/auth';
// import { metricsCache } from '../redux/ducks/auth';
// let isRefreshingToken = false;
function handleError(err) {
  return err.response && err.response.data ? err.response.data : err;
}
const e = {
  get(name, path, parameters, callback) {
    // const hash = generateHash(name + path, parameters);

    // if (metricsCache.peek(hash)) {
    //   const data = metricsCache.get(hash);
    //   callback(null, data);
    // } else {
    //   callApi();
    // }

    // // console.log(parameters);
    // function callApi() {
    axios
      .get(`/v2/${name}/${path}`, {
        params: parameters
      })
      .then(res => {
        // metricsCache.set(hash, res.data);
        if (res) {
          callback(null, res.data);
        } else {
          // TODO: 1) callback with no data, 2) error or 3) ignore? -> case when refreshing token and the UI ends up reloaded
          throw new Error('no response');
        }
      })
      .catch(err => {
        console.log('APIv2.call error', name, path, parameters, err);
        const r = handleError(err);
        callback(r, null); // TODO null is ok or {} is needed?
      });
    // }
  },

  post(name, path, parameters, callback) {
    axios
      .post(`/v2/${name}/${path}`, parameters)
      .then(res => {
        if (res) {
          callback(null, res.data);
        } else {
          // TODO: 1) callback with no data, 2) error or 3) ignore? -> case when refreshing token and the UI ends up reloaded
          throw new Error('no response');
        }
      })
      .catch(err => {
        console.log('APIv2.call error', name, path, parameters, err);
        const r = handleError(err);
        callback(r, {}); // TODO: null is ok or {} is needed?
      });
  },

  delete(name, path, parameters, callback) {
    axios
      .delete(`/v2/${name}/${path}`, parameters)
      .then(res => {
        if (res) {
          callback(null, res.data);
        } else {
          throw new Error('no response');
        }
      })
      .catch(err => {
        console.log('APIv2.call error', name, path, parameters, err);
        const r = handleError(err);
        callback(r, null);
      });
  }

  // configureInterceptors(store, token) {
  //   axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  //   axios.interceptors.response.use(
  //     response => response,
  //     error => {
  //       const originalRequest = error.config;

  //       const refreshTokenUrl = `${axios.defaults.baseURL}/v2/auth/refresh-token`;

  //       if (
  //         error.response &&
  //         error.response.status === 401 &&
  //         !originalRequest._retry &&
  //         refreshToken &&
  //         originalRequest.url !== refreshTokenUrl &&
  //         !isRefreshingToken
  //       ) {
  //         originalRequest._retry = true;
  //         isRefreshingToken = true;
  //         return store.dispatch(refreshToken());
  //       }

  //       if (
  //         error.response &&
  //         error.response.status === 401 &&
  //         originalRequest._retry &&
  //         refreshToken
  //       ) {
  //         store.dispatch(logout());
  //       }

  //       return Promise.reject(error);
  //     }
  //   );
  // }
};

export default e;
