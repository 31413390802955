import React from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import screenfull from 'screenfull';
// import get from 'lodash/get';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row
} from 'reactstrap';

// icons
import {
  MdNotifications,
  MdCropFree,
  MdFace,
  MdMail,
  MdSecurity,
  MdLogout,
  MdMenu,
  MdList
} from 'react-icons/md/';
// style
import './style.scss';
import Filter from './Filter';

// import { useAPIDataLoader } from '../../hooks/api';

export default function Header(props) {
  // TODO: commented because this is not being used and needs to be redefined
  // const notificationsResponse = useAPIDataLoader(
  //   'notifications.last_notifications',
  //   {},
  //   { cache: false }
  // );

  // const notifications = get(notificationsResponse, 'data.value.notifications', []);
  // const notificationsCount = notifications.reduce(
  //   (accum, notif) => (notif.new ? accum + 1 : accum),
  //   0
  // );

  const notifications = [];
  const notificationsCount = 0;

  return (
    <Row className="m-0">
      <div className="col-12 p-0">
        <header className="site-head d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="wrap mr-4">
              <MdMenu
                size="24"
                color="#fff"
                onClick={props.toggleNav}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
          {window._env_.REACT_APP_PRODUCT === 'true' ? (
            <>
              <div className="wrap mr-4 hide_mob">
                <img
                  height="20"
                  src={`${process.env.PUBLIC_URL}/img/${window._env_.REACT_APP_BRAND}/product-logo.png`}
                  alt={window._env_.REACT_APP_PRODUCT_TITLE}
                />
              </div>
              <div className="wrap mr-4 show_mob">
                <img
                  height="20"
                  src={`${process.env.PUBLIC_URL}/img/${window._env_.REACT_APP_BRAND}/product-logo-short.png`}
                  alt={window._env_.REACT_APP_PRODUCT_TITLE}
                />
              </div>
            </>
          ) : null}
          <div className="right-elems ml-auto d-flex">
            <div className="wrap hidden-sm-down">
              <MdCropFree size="22" color="#fff" onClick={() => screenfull.toggle()} />
            </div>
            <div className="wrap notify hidden-sm-down">
              <UncontrolledDropdown>
                <DropdownToggle tag="div">
                  <MdNotifications size="22" color="#fff" />
                  {notificationsCount > 0 ? (
                    <span className="badge badge-danger">{notificationsCount}</span>
                  ) : (
                    <span className="badge badge-info">{notificationsCount}</span>
                  )}
                </DropdownToggle>

                <DropdownMenu end style={{ minWidth: '18rem' }}>
                  {notificationsCount > 0 ? (
                    <DropdownItem header>
                      {notificationsCount} {i18n.t('notifications_new')}
                    </DropdownItem>
                  ) : (
                    <DropdownItem header>{i18n.t('notifications_none')}</DropdownItem>
                  )}

                  <DropdownItem divider />
                  {notifications.map(notification => (
                    <DropdownItem className="d-flex align-items-center">
                      <MdMail size="28" className="text-warning" />
                      <div className="ml-3">
                        <div>{notification.title}</div>
                        <div>{notification.description}</div>
                        <small className="text-muted">{moment(notification.date).fromNow()}</small>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className="wrap profile">
              <UncontrolledDropdown>
                <DropdownToggle tag="div">
                  <Button color="primary">{props.user.firstName}</Button>
                </DropdownToggle>
                <DropdownMenu end style={{ minWidth: '12rem' }}>
                  {/* <DropdownItem header>Balance: $993.4</DropdownItem>
                          <DropdownItem divider/> */}
                  <DropdownItem>
                    <MdFace size="16" />
                    &emsp;
                    <Link to="/edit-profile">{i18n.t('edit_profile')}</Link>
                  </DropdownItem>
                  <DropdownItem>
                    <MdSecurity size="16" />
                    &emsp;
                    <Link to="/edit-password">{i18n.t('edit_password')}</Link>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <MdList size="16" />
                    &emsp;
                    <Link to="/glossary">{i18n.t('info_menu_glossary')}</Link>
                  </DropdownItem>
                  <div className="text-right ml-3 mr-3 mt-2">
                    <Button block color="danger" size="sm" onClick={props.logout}>
                      <MdLogout size="15" />
                      &emsp;{i18n.t('logout')}
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            {/* {props.user.role === 'Admin' ? (
              <div className="wrap profile d-none d-md-block">
                <SignInAs {...props} />
              </div>
            ) : null} */}
          </div>
        </header>
      </div>
      {props.filter ? (
        <div className="mb-12 col-sm-12 col-md-12 p-0">
          <div className="site-head-row2 d-flex align-items-center justify-content-between p-md-3">
            <Filter {...props} />
          </div>
        </div>
      ) : null}
    </Row>
  );
}
