import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line
} from 'recharts';

import { colors } from '../../components/charts/Styles';

export default function EventsHourChart({ response, visits, poiType }) {
  if (!response || response.length === 0 || visits === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const data = [
    {
      name: '6:00am - 9:59am',
      visits: response[0].visits,
      visitsWeekend: response[0].visitsWeekend,
      valueVisits: response[0].visits / visits,
      valueVisitsWeekend: response[0].visitsWeekend / visits
    },
    {
      name: '10:00am - 1:59pm',
      visits: response[1].visits,
      visitsWeekend: response[1].visitsWeekend,
      valueVisits: response[1].visits / visits,
      valueVisitsWeekend: response[1].visitsWeekend / visits
    },
    {
      name: '2:00pm - 5:59pm',
      visits: response[2].visits,
      visitsWeekend: response[2].visitsWeekend,
      valueVisits: response[2].visits / visits,
      valueVisitsWeekend: response[2].visitsWeekend / visits
    },
    {
      name: '6:00pm - 9:59pm',
      visits: response[3].visits,
      visitsWeekend: response[3].visitsWeekend,
      valueVisits: response[3].visits / visits,
      valueVisitsWeekend: response[3].visitsWeekend / visits
    }
  ];

  const renderTooltip = function (props) {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
            zIndex: 999999
          }}
        >
          <p>
            <strong>{data.name}</strong>
          </p>
          <p>
            <span>{i18n.t(poiType === 'ooh' ? 'mobility_week_ooh' : 'mobility_week')}: </span>
            {numeral(data.visits).format('0,0')} ({numeral(data.valueVisits).format('%0.0')})
          </p>
          <p>
            <span>{i18n.t(poiType === 'ooh' ? 'mobility_weekend_ooh' : 'mobility_weekend')}: </span>
            {numeral(data.visitsWeekend).format('0,0')} (
            {numeral(data.valueVisitsWeekend).format('%0.0')})
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 5, right: 50, left: 15, bottom: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis type="number" tickFormatter={value => numeral(value).format('0.[00]a')} />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          wrapperStyle={{ zIndex: 100 }}
          content={props => renderTooltip(props)}
        />
        <Legend />
        <Line
          type="monotone"
          name={i18n.t(poiType === 'ooh' ? 'mobility_week_ooh' : 'mobility_week')}
          dataKey="visits"
          stroke={colors[0]}
          strokeWidth={2}
        />
        <Line
          type="monotone"
          name={i18n.t(poiType === 'ooh' ? 'mobility_weekend_ooh' : 'mobility_weekend')}
          dataKey="visitsWeekend"
          stroke={colors[1]}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
