import moment from 'moment';
import { createSelector } from 'reselect';

const FILTER_VERSION = 1.0;
const MIN_FILTER_VERSION = 1.0;

// Actions
const FILTER_SET_FILTER = 'filter/SET_FILTER';
const FILTER_USE_DEFAULT_FILTER = 'filter/USE_DEFAULT_FILTER';
const FILTER_CLEAR = 'filter/CLEAR';
const FILTER_SET_COMPARE_FILTER = 'filter/SET_COMPARE_FILTER';

// Reducer
// TODO: move this flag to AppConfig
const defaultStartDate = window._env_.REACT_APP_DEFAULT_START_PERIOD
  ? moment(window._env_.REACT_APP_DEFAULT_START_PERIOD)
  : moment().subtract(1, 'month');

const defaultFilter = {
  startDate: defaultStartDate.startOf('month').valueOf(),
  endDate: defaultStartDate.endOf('week').valueOf(),
  offset: moment().toDate().getTimezoneOffset(),
  pois: [],
  groupKeys: [],
  storeKey: null,
  agg: 'g'
};

const defaultCompareFilter = {
  startDate: defaultStartDate.startOf('month').valueOf(),
  endDate: defaultStartDate.endOf('week').valueOf(),
  offset: moment().toDate().getTimezoneOffset(),
  groupKeys: [],
  columns: {}
};

const initialState = {
  version: FILTER_VERSION,
  pois: [],
  defaultFilter,
  defaultCompareFilter,
  filter: {
    pdv: { ...defaultFilter, type: 'pdv' },
    ooh: { ...defaultFilter, type: 'ooh' }
  },
  compareFilter: {
    pdv: { ...defaultCompareFilter, type: 'pdv' },
    ooh: { ...defaultCompareFilter, type: 'ooh' }
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FILTER_CLEAR:
      return initialState;
    case FILTER_SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter.type]: {
            ...action.payload.filter
          }
        }
      };
    case FILTER_SET_COMPARE_FILTER:
      const cols = action.payload.compareFilter.columns
        ? action.payload.compareFilter.columns
        : state.compareFilter[action.payload.compareFilter.type].columns;
      return {
        ...state,
        compareFilter: {
          ...state.compareFilter,
          [action.payload.compareFilter.type]: {
            ...action.payload.compareFilter,
            columns: {
              ...cols
            }
          }
        }
      };
    case FILTER_USE_DEFAULT_FILTER:
      return {
        ...state,
        filter: {
          pdv: state.defaultFilter,
          ooh: state.defaultFilter
        },
        compareFilter: {
          pdv: state.defaultCompareFilter,
          ooh: state.defaultCompareFilter
        }
      };
    default:
      return state;
  }
}

// Action Creators

export function setFilter(filter) {
  return {
    type: FILTER_SET_FILTER,
    payload: {
      filter: {
        ...filter,
        ...fixFilterDates(filter)
      }
    }
  };
}

export function setCompareFilter(filter) {
  return {
    type: FILTER_SET_COMPARE_FILTER,
    payload: {
      compareFilter: {
        ...filter,
        ...fixFilterDates(filter)
      }
    }
  };
}

function fixFilterDates(filter) {
  return {
    period: filter.period ? filter.period : null,
    startDate: filter.startDate ? filter.startDate.valueOf() : null,
    endDate: filter.endDate ? filter.endDate.valueOf() : null,
    offset: moment(filter.startDate).toDate().getTimezoneOffset()
  };
}

export function clearFilter() {
  return {
    type: FILTER_CLEAR
  };
}

export function setDefaultFilter() {
  return {
    type: FILTER_USE_DEFAULT_FILTER
  };
}

// Selectors

export function defaultFilterSelector(state) {
  return {
    filter: state.filters.filter,
    compareFilter: state.filters.compareFilter,
    defaultFilter: state.filters.defaultFilter,
    defaultCompareFilter: state.filters.defaultCompareFilter,
    pois: state.filters.pois
  };
}

function loadFilterDates(filters, filterName, type) {
  const f = (type ? filters[filterName][type] : filters[filterName]) || defaultFilter;

  return {
    // startDate: moment(f.startDate),
    // endDate: moment(f.endDate),
    // offset: moment(f.startDate).toDate().getTimezoneOffset()
    startDate: moment(f.startDate).valueOf(),
    endDate: moment(f.endDate).valueOf(),
    offset: moment(f.startDate).toDate().getTimezoneOffset()
  };
}

// Selector to replace all epoch to moment objects.
export const filterSelector = createSelector(defaultFilterSelector, filters => ({
  ...filters,
  defaultFilter: {
    ...filters.defaultFilter,
    ...loadFilterDates(filters, 'defaultFilter')
  },
  filter: {
    ...filters.filter,
    pdv: {
      ...filters.filter.pdv,
      ...loadFilterDates(filters, 'filter', 'pdv')
    },
    ooh: {
      ...filters.filter.ooh,
      ...loadFilterDates(filters, 'filter', 'ooh')
    }
  },
  defaultCompareFilter: {
    ...filters.defaultCompareFilter,
    ...loadFilterDates(filters, 'defaultCompareFilter')
  },
  compareFilter: {
    ...filters.compareFilter,
    pdv: {
      ...filters.compareFilter.pdv,
      ...loadFilterDates(filters, 'compareFilter', 'pdv')
    },
    ooh: {
      ...filters.compareFilter.ooh,
      ...loadFilterDates(filters, 'compareFilter', 'ooh')
    }
  }
}));

export const isCurrentFilterValid = filters => {
  return filters.version >= MIN_FILTER_VERSION;
};
