import React from 'react';

import ReactDOM from 'react-dom';
import axios from 'axios';
import { StoreProvider } from 'redux-react-hook';
import 'toastr/build/toastr.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import API from './modules/api';
import configureStore from './modules/redux/configureStore';

const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = configureStore(persistedState);

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

const baseURL =
  process.env.NODE_ENV === 'production'
    ? window._env_.REACT_APP_MOFI_PROD_API_URL
    : window._env_.REACT_APP_MOFI_DEV_API_URL;

axios.defaults.baseURL = baseURL;

const { token } = store.getState().auth;

if (token) {
  API.configureInterceptors(store, token.accessToken);
}

ReactDOM.render(
  <StoreProvider value={store}>
    <App />
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
