import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { LeafLetMapComponent } from './LeafLetMapComponent';
import GoogleMapComponent from './GoogleMapComponent';

export default function RadarMap({ stores, onChange, defaultMap = 'll' }) {
  // if (!responseHeatmap || !responseHeatmap.hasValue('heatmap')) return null;

  const [map, setMap] = useState(defaultMap);

  return (
    <>
      <div className="map-buttons">
        <Button
          className="btn-sm"
          color={map === 'll' ? 'dark' : 'light-gray'}
          onClick={() => setMap('ll')}
        >
          OSM
        </Button>
        <Button
          className="btn-sm"
          color={map === 'g' ? 'dark' : 'light-gray'}
          onClick={() => setMap('g')}
        >
          Google
        </Button>
      </div>

      {map === 'll' ? (
        <LeafLetMapComponent pois={stores} onChange={onChange} />
      ) : (
        <GoogleMapComponent
          mapContainerStyle={{ height: `100%`, width: `auto` }}
          pois={stores}
          onChange={onChange}
        />
      )}
    </>
  );
}
