import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Row, Card, CardGroup, CardBody, CardTitle } from 'reactstrap';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { useAPIDataLoader } from '../../../hooks/api';
import { ZoneMap } from '../../../components/heatmap/ZoneMap';
import { ResponseLoading } from '../../../components/utils/Loading';
import StatsChart from '../../dashboard/StatsChart';
import { colors } from '../../../components/charts/Styles';
import LineChart from '../../../components/charts/LineChart';

export default function ViewContent({ responseRadar }) {
  const stores =
    responseRadar && responseRadar.hasValue('heatmap')
      ? responseRadar.data.value.heatmap.stores
      : [];

  const [storeSelected, setStoreSelected] = useState({});
  // const [revenueData, setRevenueData] = useState([]);
  // const [loyalToData, setLoyalToData] = useState([]);
  // const [receptiveData1, setReceptiveData1] = useState([]);
  // const [receptiveData2, setReceptiveData2] = useState([]);
  // const [receptiveData3, setReceptiveData3] = useState([]);

  const responseLoyaltyData = useAPIDataLoader('premium.getLoyaltyData', storeSelected, {
    cache: true
  });
  if (responseLoyaltyData.error) {
    console.log('getLoyaltyData error', responseLoyaltyData.error);
  }

  const onSelectPOI = selected => {
    setStoreSelected(selected);
    // if (responseLoyaltyData.hasValue('loyalToData')) {
    //   setRevenueData(responseLoyaltyData.data.value.revenueData);
    //   setLoyalToData(responseLoyaltyData.data.value.loyalToData);
    //   setReceptiveData1(responseLoyaltyData.data.value.receptiveData1);
    //   setReceptiveData2(responseLoyaltyData.data.value.receptiveData2);
    //   setReceptiveData3(responseLoyaltyData.data.value.receptiveData3);
    // }
  };

  const revenueData = responseLoyaltyData.hasValue('loyalToData')
    ? responseLoyaltyData.data.value.revenueData
    : [];
  const loyalToData = responseLoyaltyData.hasValue('loyalToData')
    ? responseLoyaltyData.data.value.loyalToData
    : [];
  const receptiveData1 = responseLoyaltyData.hasValue('loyalToData')
    ? responseLoyaltyData.data.value.receptiveData1
    : [];
  const receptiveData2 = responseLoyaltyData.hasValue('loyalToData')
    ? responseLoyaltyData.data.value.receptiveData2
    : [];
  const receptiveData3 = responseLoyaltyData.hasValue('loyalToData')
    ? responseLoyaltyData.data.value.receptiveData3
    : [];

  return (
    <div className="view-content">
      <div style={{ position: 'relative', zIndex: 9999, right: 0, top: '-3.5vw' }}>
        <ResponseLoading
          responses={[responseRadar, responseLoyaltyData]}
          className="loading-dot-white"
        />
      </div>
      <Row>
        <div className="col-sm-5 col-md-5 pl-0">
          <Card className="mb-4 pr-0">
            <div style={{ width: '100%', height: '950px' }}>
              <ZoneMap pois={stores} onChange={onSelectPOI} zoom={8} />
            </div>
          </Card>
        </div>
        <div className="col-sm-7 col-md-7 p-0">
          <CardGroup
            className="metric-card mb-4"
            style={{ flexFlow: 'row wrap', minHeight: '950px' }}
          >
            <Card style={{ flex: '3 0 0' }}>
              <CardBody className="pb-0">
                <CardTitle className="text-uppercase h6">{i18n.t('metrics_title')}</CardTitle>
                <div className="small mb-4 card-subtitle">
                  {i18n.t('mobility_trends_by_zone')}:{' '}
                  <strong>{storeSelected.name ? storeSelected.name : '--'}</strong>
                </div>
                {storeSelected._id ? (
                  <>
                    <div className="col-sm-12 col-md-12 pr-0">
                      <Card className="mb-4">
                        <div style={{ width: '100%', height: '250px' }}>
                          <LineChart
                            data={revenueData}
                            dataKeyX="date"
                            dataKeyY="revenue"
                            stroke={colors[0]}
                            format="$ 0.[00]a"
                            marginTop={20}
                            marginRight={40}
                          />
                        </div>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-12 pr-0">
                      <Card className="mb-4">
                        <Row>
                          <div className="col-sm-12 col-md-12 pr-0">
                            <div style={{ width: '100%', height: '283px' }} className="p-3">
                              <h6>{i18n.t('premium_loyalty_customer_title')}</h6>
                              <StatsChart
                                response={loyalToData}
                                dataKey="perc"
                                legend={false}
                                marginBottom={0}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                            <div style={{ width: '100%', height: '283px' }} className="p-3">
                              <h6>{i18n.t('premium_loyalty_receptive_title')}</h6>
                              <Row>
                                <div className="col-sm-4 col-md-4">
                                  <div style={{ width: '100%', height: '200px' }}>
                                    <ResponsiveContainer width="100%" height="90%">
                                      <PieChart margin={{ top: 90 }}>
                                        <Pie
                                          dataKey="value"
                                          startAngle={180}
                                          endAngle={0}
                                          data={receptiveData1}
                                          cx="50%"
                                          cy="50%"
                                          outerRadius={80}
                                          label
                                        />
                                      </PieChart>
                                    </ResponsiveContainer>
                                    <div className="medium" style={{ color: 'var(--brand-third)' }}>
                                      {i18n.t('premium_loyalty_receptive_1')}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                  <div style={{ width: '100%', height: '200px' }}>
                                    <ResponsiveContainer width="100%" height="90%">
                                      <PieChart margin={{ top: 90 }}>
                                        <Pie
                                          dataKey="value"
                                          startAngle={180}
                                          endAngle={0}
                                          data={receptiveData2}
                                          cx="50%"
                                          cy="50%"
                                          outerRadius={80}
                                          label
                                        />
                                      </PieChart>
                                    </ResponsiveContainer>
                                    <div className="medium" style={{ color: 'var(--brand-third)' }}>
                                      {i18n.t('premium_loyalty_receptive_2')}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                  <div style={{ width: '100%', height: '200px' }}>
                                    <ResponsiveContainer width="100%" height="90%">
                                      <PieChart margin={{ top: 90 }}>
                                        <Pie
                                          dataKey="value"
                                          startAngle={180}
                                          endAngle={0}
                                          data={receptiveData3}
                                          cx="50%"
                                          cy="50%"
                                          outerRadius={80}
                                          label
                                        />
                                      </PieChart>
                                    </ResponsiveContainer>
                                    <div className="medium" style={{ color: 'var(--brand-third)' }}>
                                      {i18n.t('premium_loyalty_receptive_3')}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    </div>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
    </div>
  );
}
