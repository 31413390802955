import { point, buffer, featureCollection, pointsWithinPolygon, polygon, bbox } from '@turf/turf';

export const convertPois = pois => {
  return pois && pois.length > 0
    ? featureCollection(pois.map(p => point([p.longitude, p.latitude], p)))
    : [];
};

export const bufferPois = (pois, radius) => {
  return pois && pois.length > 0
    ? featureCollection(
        pois.map(p => buffer(point([p.longitude, p.latitude]), radius, { units: 'metres' }))
      )
    : null;
};

export const findWithin = (pois, buffered) =>
  buffered ? pointsWithinPolygon(pois, buffered) : pois;

export const filterPois = (pois, areaPois, radius) => {
  if (radius === 0) return pois;

  const converted = convertPois(pois);
  const buffered = bufferPois(areaPois, radius);

  const filtered = findWithin(converted, buffered);

  // now recover pois data only for those that where found within polygons
  const f = filtered && filtered.features ? filtered.features.map(f => f.properties) : [];

  return f;
};

export const findWithinArea = (pois, area) => {
  const p = polygon(area);
  const filtered = pointsWithinPolygon(convertPois(pois), p);
  // now recover pois data only for those that where found within polygons
  const f = filtered && filtered.features ? filtered.features.map(f => f.properties) : [];
  return f;
};

export const bboxPois = pois => {
  if (!pois || pois.length === 0) return null;
  const converted = convertPois(pois);
  return bbox(converted);
};

const e = {
  filterPois,
  convertPois,
  bufferPois,
  findWithin,
  bboxPois
};

export default e;
