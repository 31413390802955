import React from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';

import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';
import { filterSelector } from '../../../redux/ducks/filters';

export default function NewAudit(props) {
  const { poiType, fullMonth } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  return (
    <div className="view">
      <ViewHeader title={i18n.t('audit_title')} subtitle={i18n.t('audit_subtitle')} />
      <ViewContent fullMonth={fullMonth} filter={typeFilter} />
    </div>
  );
}
