import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import i18n from 'i18n-js';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line
} from 'recharts';

export default function ScatterChart({
  data,
  dataKeyX,
  dataKeyY1,
  strokeY1,
  dataKeyY2,
  strokeY2,
  format
}) {
  if (!data || data.length === 0) return <div className="text_check">{i18n.t('no_data')}</div>;

  function CustomTooltip({ active, payload, label }) {
    if (active) {
      const i = payload.length - 1;
      return (
        <div>
          <p>
            <b>{moment.utc(payload[i].payload.date).format('YYYY-MM-DD')}</b>
          </p>
          <hr />
          <p style={{ color: payload[i].color }}>
            {` ${payload[i].dataKey}`} : {` ${numeral(payload[i].value).format(format)}`}
          </p>
        </div>
      );
    }

    return null;
  }

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{ top: 10, bottom: 10, right: 10, left: 10 }}
        padding={{ right: 10, left: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} tickFormatter={value => moment.utc(value).format('DD')} />
        <YAxis
          type="number"
          tickFormatter={value => numeral(value).format(format)}
          domain={[0, 2000000]}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          wrapperStyle={{ borderColor: '#000', backgroundColor: '#fff', padding: 10 }}
          content={<CustomTooltip />}
        />
        <Legend />
        <Line type="monotone" dataKey={dataKeyY1} stroke={strokeY1} strokeWidth={2} />
        <Line type="monotone" dataKey={dataKeyY2} stroke={strokeY2} strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
}
