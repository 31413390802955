import React from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';

import { loadFromLocalStorage, buildModuleKey } from './utils';
import PlannerStructure from './PlannerStructure';

import { filterSelector } from '../../redux/ducks/filters';
export default function PlannerBuilder(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const module = buildModuleKey(poiType);

  const workingData = {
    workingFilter: loadFromLocalStorage('workingFilter', module),
    mapPois: loadFromLocalStorage('mapPois', module),
    reach: loadFromLocalStorage('reach', module)
  };

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('planner_menu')}</h1>
          <p className="mb-0">{i18n.t('campaign_builder')}</p>
        </header>
      </div>
      <PlannerStructure
        filter={typeFilter}
        workingData={workingData}
        type={props.user.type}
        poiType={poiType}
      />
    </div>
  );
}
