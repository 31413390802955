import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import CustomizedAxisTick from '../../components/charts/CustomizedAxisTick';

import { colors } from '../../components/charts/Styles';

export default function InterestsChart({ response, typeKey = 'type', valueKey = 'total_perc' }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const data = response.map((x, index) => ({
    ...x,
    color: colors[index]
  }));

  const renderTooltip = function (props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0];

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 20,
            padding: 10,
            width: '50%'
          }}
        >
          <p>
            <b style={{ color: data.payload.color }}>{data.payload.name}</b>
            <br />
            <b>{numeral(data.value).format('%0,0.[00]')}</b>
            <br />
            <span>{data.payload.description}</span>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <BarChart data={data} margin={{ top: 5, right: 50, left: 0, bottom: 30 }}>
        <YAxis type="number" tickFormatter={value => numeral(value).format('%0')} />
        <XAxis
          dataKey={typeKey}
          interval={0}
          tick={<CustomizedAxisTick data={data} iconField="category_icon" fontSize={8} />}
        />

        <Tooltip content={props => renderTooltip(props)} />
        <Bar dataKey={valueKey} name={i18n.t('share')} barSize={20}>
          {data.map((entry, index) => (
            <Cell fill={colors[index]} key={index} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
