import React from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';
import { useAPIv2DataLoader } from '../../../hooks/api';
import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';
import LoadingModal from '../../../components/utils/LoadingModal';

export default function AuditDetails(props) {
  // const auditResponse = useAPIDataLoader('audit.getOne', [props.match.params.id]);

  // if (auditResponse.error) {
  //   console.log('error', auditResponse.error);
  //   if (auditResponse.error.response && auditResponse.error.response.data) {
  //     toastr.error(`Error: ${auditResponse.error.response.data.message}`);
  //   }
  // }

  // let audit = null;

  // if (auditResponse.hasValue('audit')) audit = auditResponse.data.value.audit;

  const campaignResponse = useAPIv2DataLoader(
    'audit',
    `campaign/${props.match.params.id}`,
    {},
    { cache: false, responseHolder: `campaign` }
  );

  if (campaignResponse.error) {
    console.log('error', campaignResponse.error);
    if (campaignResponse.error.response && campaignResponse.error.response.data) {
      toastr.error(`Error: ${campaignResponse.error.response.data.message}`);
    }
  }

  if (!campaignResponse || !campaignResponse.hasValue('campaign')) {
    //return <div className="text_check">{i18n.t('campaign_loading')}</div>;

    return <LoadingModal isOpen={true} text={i18n.t('campaign_loading')} />;
  }

  const { campaignConfig, campaignResponseData } = campaignResponse.data.value.campaign;

  // console.log(campaignResponse);

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('audit_title')}
        subtitle={campaignConfig ? campaignConfig.periodLabel : ''}
      />
      <ViewContent campaignResponseData={campaignResponseData} />
    </div>
  );
}
