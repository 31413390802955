import React from 'react';

import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';

import DataValueTooltip from './DataValueTooltip';

export default function BlocksChart({ data, dataKey, stroke, fill, format, customLabel }) {
  if (!data || data.length === 0) return null;

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{ top: 5, bottom: 0, right: 0, left: 0 }}
        padding={{ right: 10, left: 10 }}
      >
        <Tooltip
          content={
            <DataValueTooltip customLabel={customLabel} format={format} dateFormat="MMMM DD" />
          }
        />
        <Area
          type="monotone"
          dataKey={dataKey}
          stroke={stroke}
          fill={fill}
          strokeWidth={2}
          fillOpacity=".8"
          dot
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
