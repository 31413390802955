import React, { useState } from 'react';
import i18n from 'i18n-js';
import { StepButton } from './StepButton';
import { Input } from 'reactstrap';

export function ConfigStep(props) {
  // const [disabled, setDisabled] = useState(false);

  const [radiusOOH, setRadiusOOH] = useState(50);
  const [radiusPDV, setRadiusPDV] = useState(100);
  const [dwellTimePDV, setDwellTimePDV] = useState([1, 60]);
  const [attributionWindow, setAttributionWindow] = useState(15);

  const { setCampaignValues, submit } = props;
  const maxRangeValueMts = 500;
  const maxRangeValueMinutes = 500;
  const maxRangeValueDays = 60;

  const onApplyConfig = value => {
    // TODO: validate config values

    setCampaignValues({
      config: {
        radiusOOH,
        radiusPDV,
        dwellTimePDV,
        attributionWindow
      }
    });
    submit();
    // setDisabled(false);
  };

  return (
    <div>
      <div className="col-12 p-10 pl-0">
        <h6 className="subtitle pt-2 text-uppercase">{i18n.t('audit_campaign_config')}</h6>
        <div className="w-75">
          <div className="align-items-center d-flex">
            <div className="col-4">{i18n.t('audit_config_ooh_radius')}:</div>

            <div className="align-items-center d-flex">
              <Input
                id="distance"
                type="range"
                value={radiusOOH}
                tooltip="on"
                min={0}
                max={maxRangeValueMts}
                step={50}
                style={{ width: '60%' }}
                onChange={v => {
                  setRadiusOOH(parseInt(v.target.value));
                }}
              />
              <Input
                type="number"
                value={radiusOOH}
                className="font-weight-light form-control form_item m-0 p-0 text-center w-25"
                min={0}
                onChange={v => {
                  const value = parseInt(v.target.value || 0);
                  if (value >= 0 && value <= maxRangeValueMts) {
                    setRadiusOOH(value);
                  }
                }}
              />
              <span>mts</span>
            </div>
          </div>
          <div className="align-items-center d-flex">
            <div className="col-4">{i18n.t('audit_config_pdv_radius')}:</div>

            <div className="align-items-center d-flex">
              <Input
                id="distance"
                type="range"
                value={radiusPDV}
                tooltip="on"
                min={0}
                max={maxRangeValueMts}
                step={50}
                style={{ width: '60%' }}
                onChange={v => {
                  setRadiusPDV(parseInt(v.target.value));
                }}
              />
              <Input
                type="number"
                value={radiusPDV}
                className="font-weight-light form-control form_item m-0 p-0 text-center w-25"
                min={0}
                onChange={v => {
                  const value = parseInt(v.target.value || 0);
                  if (value >= 0 && value <= maxRangeValueMts) {
                    setRadiusPDV(value);
                  }
                }}
              />
              <span>mts</span>
            </div>
          </div>

          <div className="align-items-center d-flex">
            <div className="col-4">{i18n.t('audit_config_pdv_dwell_time')}:</div>

            <div className="align-items-center d-flex">
              <Input
                type="number"
                value={dwellTimePDV[0]}
                className="font-weight-light form-control form_item m-0 p-0 text-center w-25"
                min={0}
                onChange={v => {
                  const value = parseInt(v.target.value || 0);
                  if (value >= 0 && value <= dwellTimePDV[1]) {
                    setDwellTimePDV([value, dwellTimePDV[1]]);
                  }
                }}
              />
              {i18n.t('up_to')}
              <Input
                type="number"
                value={dwellTimePDV[1]}
                className="font-weight-light form-control form_item m-0 p-0 text-center w-25"
                min={0}
                onChange={v => {
                  const value = parseInt(v.target.value || 0);
                  if (value >= dwellTimePDV[0] && value <= maxRangeValueMinutes) {
                    setDwellTimePDV([dwellTimePDV[0], value]);
                  }
                }}
              />
              <span>{i18n.t('audit_config_pdv_dwell_time_mins')}</span>
            </div>
          </div>

          <div className="align-items-center d-flex">
            <div className="col-4">{i18n.t('audit_config_attr_window')}:</div>

            <div className="align-items-center d-flex">
              <Input
                type="number"
                value={attributionWindow}
                className="font-weight-light form-control form_item m-0 p-0 text-center w-25"
                min={0}
                onChange={v => {
                  const value = parseInt(v.target.value || 0);
                  if (value >= 0 && value <= maxRangeValueDays) {
                    setAttributionWindow(value);
                  }
                }}
              />
              <span>{i18n.t('audit_config_attr_window_days')}</span>
            </div>
          </div>
        </div>
      </div>
      <StepButton {...props} step={props.step} disabled={false} submit={onApplyConfig} />
    </div>
  );
}
