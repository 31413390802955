import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';

import VisitorsDailyChart from './VisitorsDailyChart';

import { ResponseLoading } from '../../components/utils/Loading';

export default function VisitorsByDayCard({ responseVisits, poiType }) {
  return (
    <CardGroup className="metric-card mb-4">
      <Card style={{ flex: '3 0 0' }}>
        <CardBody>
          <CardTitle className="text-uppercase h6">
            {i18n.t(poiType === 'ooh' ? 'visitors_title_ooh' : 'visitors_title')}
            <ResponseLoading response={responseVisits} />
          </CardTitle>
          <div className="small mb-4 card-subtitle">{i18n.t('by_day')}</div>
          <div style={{ width: '100%', height: '280px' }}>
            <VisitorsDailyChart
              visitorsAndVisitsResponse={
                responseVisits.hasValue('metricsDaily')
                  ? responseVisits.data.value.metricsDaily
                  : null
              }
              visitsTitle={poiType === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title'}
              uniquesTitle={
                poiType === 'ooh'
                  ? 'total_unique_visitors_title_ooh'
                  : 'total_unique_visitors_title'
              }
            />
          </div>
        </CardBody>
      </Card>
    </CardGroup>
  );
}
