import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DatePicker, DateRangePicker } from 'rsuite';
import './rsuite-default-custom.scss';

export default function CustomDatePicker({ fullMonth = true, value, valueRange, onChange }) {
  const [isLangLoaded, setLangLoaded] = useState(false);

  // load locale and repaint date components
  useEffect(() => {
    (async () => {
      // Dynamically imported module (runtime)

      if (moment.locale() !== window._env_.REACT_APP_LANGUAGE) {
        await import(`moment/locale/${window._env_.REACT_APP_LANGUAGE}`);
        moment.locale(window._env_.REACT_APP_LANGUAGE);

        setLangLoaded(true);
      } else {
        setLangLoaded(true);
      }
    })();
  }, []);

  // TODO: return a placeholder
  if (!isLangLoaded) return null;

  return fullMonth ? (
    <DatePicker
      appearance="default"
      format="MMMM YYYY"
      block
      size="lg"
      value={value}
      renderValue={value => `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`}
      ranges={[]}
      onChange={onChange}
      cleanable={false}
    />
  ) : (
    <DateRangePicker
      appearance="default"
      hoverRange="week"
      isoWeek
      block
      size="lg"
      showOneCalendar
      oneTap
      value={valueRange}
      renderValue={value =>
        `${moment(value[0]).format('MMMM DD')} - ${moment(value[1]).format('MMMM DD')}`
      }
      ranges={[]}
      onChange={onChange}
      cleanable={false}
    />
  );
}
