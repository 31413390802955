import React from 'react';
// import numeral from 'numeral';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle, Row, CardGroup } from 'reactstrap';
import StatsChart from './StatsChart';
import AgeRangeChart from './AgeRangeChart';
import { ResponseLoading } from '../../components/utils/Loading';
import { colors } from '../../components/charts/Styles';

export default function DemographicsCard({ fullDemosObj }) {
  const handleOnClick = (e, param) => {
    fullDemosObj.fullDemosFilter[param] =
      fullDemosObj.fullDemosFilter[param] === e.name ? null : e.name;

    fullDemosObj.setFullDemosFilter({ ...fullDemosObj.fullDemosFilter });
  };

  const demosColors = {
    F: colors[0],
    M: colors[1],
    '20_24': colors[0],
    '25_29': colors[1],
    '30_39': colors[2],
    '40_49': colors[3],
    '50_59': colors[4],
    '60_69': colors[5],
    '70_plus': colors[6],
    A: colors[0],
    B: colors[1],
    C: colors[2],
    D: colors[3],
    E: colors[4]
  };

  const aggDemos = (key, withLabel = false) => {
    const d = fullDemosObj.fullDemos
      .map(e => ({
        // name: withLabel ? i18n.t(e[key]) : e[key],
        name: e[key],
        value: parseInt(e.unique)
      }))
      .reduce((r, a) => {
        r[a.name] = (r[a.name] || 0) + a.value;
        return r;
      }, {});

    const r = Object.keys(d)
      .map(k => ({ name: k, value: d[k], color: demosColors[k] }))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    return r;
  };

  const genders = aggDemos('demographics_gender');
  const ages = aggDemos('demographics_age_range', true);
  const classes = aggDemos('demographics_class');

  return (
    <Row>
      <div className="mb-4 col-sm-12 col-md-12">
        <CardGroup className="metric-card mb-12">
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('demographics_filter_title')}
                <ResponseLoading response={fullDemosObj} />
              </CardTitle>
              <div className="small mb-4 mt-2 card-subtitle">{i18n.t('click_to_filter')}</div>

              <Row>
                <div className="mb-4 col-sm-6 col-md-4">
                  <Card>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">{i18n.t('gender')}</CardTitle>
                      <div className="pieChart">
                        <StatsChart
                          response={genders}
                          onClick={e => handleOnClick(e, 'demographics_gender')}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mb-4 col-sm-6 col-md-4">
                  <Card>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">{i18n.t('age_range')}</CardTitle>
                      <div className="pieChart">
                        <AgeRangeChart
                          maxValue={fullDemosObj.totalUniques}
                          response={ages}
                          onClick={e => handleOnClick(e, 'demographics_age_range')}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mb-4 col-sm-12 col-md-4">
                  <Card>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('socioeconomic_level')}
                      </CardTitle>
                      <div className="pieChart">
                        <StatsChart
                          response={classes}
                          onClick={e => handleOnClick(e, 'demographics_class')}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </Row>
  );
}
