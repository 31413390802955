import React from 'react';
import i18n from 'i18n-js';

import { CardGroup, Card, CardBody, CardTitle, Row, Button } from 'reactstrap';

import DashboardTopFDCard from '../dashboard/DashboardTopFDCard';
import VisitsMetricsFDCard from '../dashboard/VisitsMetricsFDCard';
import { openNewWindow } from '../../../routeUtils';
// import VisitsTrendsFDCard from '../dashboard/VisitsTrendsFDCard';

export default function MetricsCard({ filter, fullDemosObj, storeSelected, poiType }) {
  return (
    <Row className="mh-100 h-100">
      <div className="col-sm-12 col-md-12 mh-100">
        <CardGroup className="metric-card mh-100 h-100" style={{ flexFlow: 'row wrap' }}>
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">{i18n.t('metrics_title')}</CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(poiType === 'ooh' ? 'by_ooh' : 'by_poi')}:{' '}
                <strong>{storeSelected ? storeSelected.name : '--'}</strong>
              </div>
              {storeSelected ? (
                <>
                  <DashboardTopFDCard
                    filter={filter}
                    fullDemosObj={fullDemosObj}
                    groupCount={1}
                    showPerc={false}
                    poiType={poiType}
                  />

                  <VisitsMetricsFDCard fullDemosObj={fullDemosObj} poiType={poiType} />

                  <div className="mt-2 col-12 d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => openNewWindow(`/dashboard/${storeSelected._id}`, ['type'])}
                    >
                      {i18n.t('go_to_dashboard')}
                    </Button>
                  </div>
                  {/* <VisitsTrendsFDCard fullDemosObj={fullDemosObj} poiType={poiType} /> */}
                </>
              ) : null}
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </Row>
  );
}
