import React from 'react';
import ReactSelect from 'react-select';
import { CardTitle } from 'reactstrap';
import moment from 'moment';
import { useAPIDataLoader } from '../../hooks/api';
import { ResponseLoading } from '../utils/Loading';
import CustomDatePicker from './CustomDatePicker';

export default function PeriodFilter(title, selectedPeriod, onSelectParam, periodFixed) {
  const responsePeriods = useAPIDataLoader(
    'periods.getPeriods',
    {},
    { cache: true, skip: !periodFixed }
  );
  if (responsePeriods.error) {
    console.log('getPeriods error', responsePeriods.error);
  }

  return periodFixed ? (
    <>
      <CardTitle className="text-uppercase small font-weight-bold">
        {title}
        <ResponseLoading response={responsePeriods} />
      </CardTitle>
      <div style={{ width: '100%', height: '60px' }}>
        <ReactSelect
          value={selectedPeriod}
          options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
          onChange={p => {
            onSelectParam(p);
          }}
          getOptionValue={option => option.oid}
        />
      </div>
    </>
  ) : (
    <>
      <CardTitle className="text-uppercase small font-weight-bold">{title}</CardTitle>
      <div style={{ width: '100%', height: '60px' }}>
        <CustomDatePicker
          value={selectedPeriod ? moment(selectedPeriod.timestamp).toDate() : new Date()}
          onChange={value => {
            onSelectParam(moment(value).toDate().getTime());
          }}
        />
      </div>
    </>
  );
}
