import React from 'react';
// import React, { useRef } from 'react';
import i18n from 'i18n-js';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { CardBody, CardTitle, Collapse } from 'reactstrap';

export default function Accordion(props) {
  const { id, title, children, open, setOpen } = props;

  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const isOpen = id => {
    return open === id;
  };

  return (
    <CardBody className="pb-0 border-bottom border-light">
      <CardTitle className="text-uppercase h6" onClick={() => toggle(id)}>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ cursor: 'pointer' }}
        >
          {i18n.t(title)}
          {isOpen(id) ? <MdKeyboardArrowDown size={25} /> : <MdKeyboardArrowRight size={25} />}
        </div>
      </CardTitle>
      <Collapse isOpen={isOpen(id)}>{children}</Collapse>
    </CardBody>
  );
}
