import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import i18n from 'i18n-js';

import {
  ScatterChart as ReScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts';

export default function ScatterChart({ data0, data1, dataKey }) {
  if (!data0 || data0.length === 0 || !data1 || data1.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const years = [
    ...new Set(data0.map(x => moment.utc(moment.utc(x.date).format('YYYY'), 'YYYY').valueOf()))
  ];
  years.push(
    moment
      .utc(years[years.length - 1])
      .add(1, 'year')
      .valueOf()
  );

  function CustomTooltip({ active, payload, label }) {
    if (active) {
      return (
        <div>
          <p>
            <b>{moment(payload[0].value).format('YYYY-MM-DD')}</b>
          </p>
          <hr />
          <p style={{ color: payload[1].color }}>
            {` ${payload[1].name}`} :{' '}
            {` ${numeral(payload[1].value).format(payload[1].value > 10000000 ? '0.000a' : '0,0')}`}
          </p>
        </div>
      );
    }

    return null;
  }

  return (
    <ResponsiveContainer>
      <ReScatterChart
        height={400}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={['auto', 'auto']}
          dataKey="date"
          name="date"
          ticks={years}
          tickFormatter={tick => moment.utc(tick).format('YYYY')}
          interval={0}
        />

        <YAxis
          type="number"
          dataKey={dataKey}
          name={dataKey}
          domain={['auto', 'auto']}
          tickFormatter={v => numeral(v).format('0,0')}
        />

        <ZAxis type="number" dataKey={dataKey} range={[3, 3]} zAxisId="visitsMax" />
        <ZAxis type="number" dataKey={dataKey} range={[1, 1]} zAxisId="visitsMaxLine" />

        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          wrapperStyle={{ borderColor: '#000', backgroundColor: '#fff', padding: 10 }}
          content={<CustomTooltip />}
        />
        <Scatter data={data0} shape="circle" zAxisId="visitsMax">
          {data0.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Scatter>
        <Scatter data={data1} shape="circle" zAxisId="visitsMaxLine">
          {data1.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Scatter>
      </ReScatterChart>
    </ResponsiveContainer>
  );
}
