/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { Card, CardBody } from 'reactstrap';
import API from '../../api';
import Paginator from '../../components/pagination/Paginator';
import { Loading } from '../../components/utils/Loading';
import { Filter, SortIcon } from '../../components/filter/Filter';

function ApplicationRow({ poi, user }) {
  return (
    <tr>
      <td>{poi.name}</td>
      <td>{poi.address}</td>
      {poi.address1 ? <td>{poi.address1}</td> : null}
      <td>{poi.city}</td>
      <td>{poi.zipcode}</td>
      <td>{poi.group}</td>
    </tr>
  );
}

function ViewContent({
  totalItems,
  currentPage,
  setPage,
  pois,
  user,
  filter,
  handleFilterChange,
  sortBy,
  handleSortBy
}) {
  return (
    <div>
      <div className="row">
        <div className="col-md-7 col-sm-7 col-xs-12">
          <h6 className="mb-4 text-uppercase">{i18n.t('admin_pois_list')}</h6>
        </div>
        <Filter filter={filter} handleFilterChange={handleFilterChange} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'name')}
            >
              {i18n.t('admin_pois_name')}
              <SortIcon sortBy={sortBy} field="name" />
            </th>
            <th className="mb-2" style={{ width: '30%', minWidth: '30%' }}>
              {i18n.t('admin_pois_address')}
            </th>

            <th className="mb-2" style={{ width: '20%', minWidth: '20%' }}>
              {i18n.t('admin_pois_city')}
            </th>

            <th className="mb-2" style={{ width: '10%', minWidth: '10%' }}>
              {i18n.t('admin_pois_zipcode')}
            </th>
            <th
              className="mb-2"
              style={{ width: '10%', minWidth: '10%' }}
              onClick={handleSortBy.bind(this, 'group')}
            >
              {i18n.t('admin_pois_type')}
              <SortIcon sortBy={sortBy} field="group" />
            </th>
          </tr>
        </thead>
        <tbody>
          {pois && pois.map((poi, i) => <ApplicationRow key={i} poi={poi} user={user} />)}
        </tbody>
      </table>
      <Paginator totalItems={totalItems} currentPage={currentPage} setPage={setPage} />
    </div>
  );
}

const defaultResponse = {
  loaded: false,
  data: {
    pois: [],
    count: 0
  }
};

export default function POIs(props) {
  const {
    match: {
      params: { page = 1 }
    },
    history,
    user
  } = props;

  const [poisResponse, setResponse] = useState(defaultResponse);
  const [filter, setFilter] = useState('');
  const [sortBy, setSortBy] = useState({ key: 1 });

  useEffect(() => {
    setResponse(pr => {
      pr.loaded = false;
    });
    API.noCacheCall('pois.getAll', { page, filter, sortBy }, (err, response) => {
      setResponse({
        error: err,
        data: response,
        loaded: true
      });
    });
  }, [page, filter, sortBy]);

  if (poisResponse.error) {
    console.log('error', poisResponse.error);
  }

  const setPage = page => {
    history.push(`/pois/${page}`);
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const handleSortBy = field => {
    const currentSortBy = sortBy || {};

    const _sortBy = currentSortBy[field]
      ? {
          [field]: currentSortBy[field] * -1
        }
      : {
          [field]: 1
        };

    setSortBy(_sortBy);
  };

  const { pois, count } = poisResponse.data;

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('admin_menu_poi')}</h1>
          <p className="mb-0">{i18n.t('admin_poi_title')}</p>
        </header>
      </div>
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <Loading loaded={poisResponse.loaded} />
            <ViewContent
              totalItems={count}
              currentPage={page}
              setPage={setPage}
              pois={pois}
              user={user}
              filter={filter}
              handleFilterChange={handleFilterChange}
              sortBy={sortBy}
              handleSortBy={handleSortBy}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
