import React from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import ResultTable from './ResultTable';

export default function ResultCard({ responseResult }) {
  let summary = null;

  // if (responseResult.hasValue('auditSummary')) summary = responseResult.data.value.auditSummary;
  summary = responseResult.auditSummary;

  return (
    <Row>
      <div className="mb-4 col-sm-12 col-md-12">
        <Card>
          <CardBody>
            <CardTitle className="text-uppercase h6">{i18n.t('audit_result_title')}</CardTitle>
            <div className="small mb-4 card-subtitle">{i18n.t('audit_result_subtitle')}</div>
            <div>
              <ResultTable results={summary} />
            </div>
          </CardBody>
        </Card>
      </div>
    </Row>
  );
}
