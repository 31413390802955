import React from 'react';
import { MdAutorenew } from 'react-icons/md';
import { Modal } from 'reactstrap';

export default function LoadingModal({ isOpen, text = null }) {
  return (
    <Modal isOpen={isOpen} backdrop={'static'} centered={true} className="mt-0">
      <div className="custom-ui">
        <MdAutorenew size={25} className="rotate" /> {text}
      </div>
    </Modal>
  );
}
