import React from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';
import './heatmap.scss';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useDashboardData } from '../../hooks/metrics/esdata';
import { filterSelector } from '../../redux/ducks/filters';

export default function Heatmap(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const { responseHeatmap } = useDashboardData(typeFilter, {
    qHeatmap: true
  });

  return (
    <div className="view">
      <ViewHeader title={i18n.t('heatmaps_title')} />
      <ViewContent responseHeatmap={responseHeatmap} poiType={poiType} />
    </div>
  );
}
