import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';
import OfflineInterestsCard from './OfflineInterestsCard';
import TechCard from './TechCard';
import RankingChart from '../../ranking/RankingChart';
import { colors } from '../../../components/charts/Styles';
import ResultCard from './ResultCard';
import VisitorsCard from './VisitorsCard';
import AgeRangeCard from './AgeRangeCard';
import DwellTimeCard from './DwellTimeCard';
import ControlGroupCard from './ControlGroupCard';

export default function ViewContent({ campaignResponseData }) {
  if (!campaignResponseData) return <div className="text_check">{i18n.t('no_data')}</div>;

  const auditGroups = campaignResponseData ? campaignResponseData.auditGroups : [];

  const rankingPDV = campaignResponseData.rankingVisits;

  const rankingPDVAttr = campaignResponseData.rankingVisitsAttr;

  const rankingOOHHits = campaignResponseData.rankingOOHHits;

  const rankingOOHUnique = campaignResponseData.rankingOOHUnique;

  return (
    <div className="view-content">
      <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <ResultCard responseResult={campaignResponseData} />
            <ControlGroupCard responseControlGroup={campaignResponseData} />
            <VisitorsCard responseVisitors={campaignResponseData} />
            <DwellTimeCard responseDwellTime={campaignResponseData} auditGroups={auditGroups} />
            <AgeRangeCard responseAgeRange={campaignResponseData} auditGroups={auditGroups} />
            <OfflineInterestsCard responseVisits={campaignResponseData} poiType="pdv" />
            <TechCard
              responseAppBundles={campaignResponseData}
              responseTech={campaignResponseData}
              poiType="pdv"
            />
            {rankingOOHHits && rankingOOHHits.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_ooh_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_ooh_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingOOHHits} color={colors[1]} type="ooh" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingOOHUnique && rankingOOHUnique.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_attribution_ooh_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_attribution_ooh_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingOOHUnique} color={colors[1]} type="ooh" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingPDV && rankingPDV.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_pdv_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_pdv_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingPDV} color={colors[3]} type="pdv" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingPDVAttr && rankingPDVAttr.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_attribution_pdv_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_attribution_pdv_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingPDVAttr} color={colors[3]} type="pdv" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
          </CardBody>
        </Card>
      </CardGroup>
    </div>
  );
}
