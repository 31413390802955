import React from 'react';
import i18n from 'i18n-js';
import { Row } from 'reactstrap';
import toastr from 'toastr';
import { useAPIDataLoader } from '../../hooks/api';
import AudienceInsights from './AudienceInsights';

function ViewContent({ response, type }) {
  if (!response || !response.data || !response.data.value) {
    return <div className="text_check">{i18n.t('audience_loading')}</div>;
  }

  return (
    <div className="view-content view-dashboard">
      <Row>
        <AudienceInsights
          responseData={response.data.value.audience}
          allMatch={response.data.value.allMatch}
          filter={response.data.value.audience.filter}
        />
      </Row>
    </div>
  );
}

export default function AudienceDetails(props) {
  const audienceResponse = useAPIDataLoader('audiences.getOne', [props.match.params.id]);
  if (audienceResponse.error) {
    console.log('error', audienceResponse.error);
    if (audienceResponse.error.response && audienceResponse.error.response.data) {
      toastr.error(`Error: ${audienceResponse.error.response.data.message}`);
    }
  }

  return (
    <div className="view">
      <div className="view-header d-flex">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('audience_title')}</h1>
          <p className="mb-0">{i18n.t('audience_details')}</p>
        </header>
        <div className="ml-auto d-sm-flex d-none mt-2">
          <div className="d-none d-md-block pt-3">
            {/* <Button href={`/audiences/datafeed/${props.match.params.id}`} color="success">
              Request Data Feed
            </Button>{' '}
            <Button href={`/audiences/activate/${props.match.params.id}`} color="success">
              Activate with Partner
            </Button> */}
          </div>
        </div>
      </div>
      <ViewContent response={audienceResponse} />
    </div>
  );
}
