import React, { useState } from 'react';
import i18n from 'i18n-js';
import { MdAddCircle, MdDvr, MdEdit } from 'react-icons/md/';
import ModalFilter from '../../header/ModalFilter';
import { StepButton } from './StepButton';

export function POIStep(props) {
  const [modal, setModal] = useState(false);
  const [poi, setPoi] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { filter, setCampaignValues, poiType } = props;

  filter.type = poiType;

  const onSelectPOI = (node, params) => {
    const selected = {
      label: node.name,
      value: node.group_hash,
      count: node.groupCount
    };

    setPoi(selected);
    setModal(false);
    setDisabled(false);

    setCampaignValues({
      [poiType]: selected
    });
  };

  return (
    <div>
      <ModalFilter modal={modal} setModal={setModal} onSelect={onSelectPOI} filter={filter} />
      <div className="col-12 p-10 pl-0 pr-0 d-flex flex-column align-items-center">
        <div className="col-12 p-10 pl-0">
          <h6 className="subtitle pt-2">
            {i18n.t(poiType === 'ooh' ? 'segment_label_ooh' : 'segment_label')}
          </h6>
          {/* <ResponseLoading response={responseFilters} className="loading-dot-white" /> */}
          <div
            className="filter-picker-value"
            onClick={() => {
              setModal(true);
            }}
          >
            {poi ? poi.label : '--'}
            <MdEdit size="18" color="#005384" style={{ float: 'right', cursor: 'pointer' }} />
          </div>
        </div>
        <div className="mt-4 d-flex">
          <MdAddCircle size={30} style={{ color: 'var(--brand-third)', cursor: 'pointer' }} />
          {poiType === 'ooh' && (
            <MdDvr
              size={30}
              className="ml-2"
              style={{ color: 'var(--brand-third)', cursor: 'pointer' }}
            />
          )}
        </div>
      </div>
      <StepButton {...props} step={props.step} disabled={disabled} />
    </div>
  );
}
