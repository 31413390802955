import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import { colors } from '../../components/charts/Styles';
import CustomizedAxisTick from '../../components/charts/CustomizedAxisTick';

export default function TopCategoriesChart({ response }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;
  const data = response.map(x => ({
    name: x.name,
    category_value: parseFloat(x.category_perc * 100),
    category_icon: x.category_icon,
    app_name: x.apps[0].title,
    app_value: x.apps[0].perc,
    app_icon: x.apps[0].icon.replace('https', 'http'),
    apps: x.apps
  }));

  const renderTooltip = function (props) {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data0 = payload[0];
      const data1 = payload[1];

      const apps = data1.payload.apps.map(a => (
        <div key={a.app_bundle_id}>
          <p>
            <img src={a.icon} alt="" width="24" height="24" referrerPolicy="no-referrer" />{' '}
            {a.title} -{' '}
            <span style={{ color: data1.color }}>
              {i18n.t('share')}: {numeral(a.perc / 100).format('%0,0.[00]')}
            </span>
          </p>
        </div>
      ));

      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #999', margin: 0, padding: 10 }}>
          <p>
            <b>{data0.payload.name}</b> -{' '}
            <span style={{ color: data0.color }}>
              {i18n.t('share')}: {numeral(data0.value / 100).format('%0,0.[00]')}
            </span>
          </p>
          {apps}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <BarChart data={data} margin={{ top: 5, right: 50, left: 0, bottom: 50 }}>
        <XAxis
          dataKey="name"
          interval={0}
          tick={
            <CustomizedAxisTick
              data={data}
              iconField="category_icon"
              iconField2="app_icon"
              fontSize={8}
            />
          }
        />
        <YAxis type="number" />
        {/* <Tooltip formatter={value => numeral(value / 100).format('%0,0')} /> */}
        <Tooltip content={props => renderTooltip(props)} />
        <Bar dataKey="category_value" fill={colors[0]} name="Category" stackId="cat" />
        <Bar dataKey="app_value" fill={colors[1]} name="App" stackId="app" />
      </BarChart>
    </ResponsiveContainer>
  );
}
