import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Formik, Form, Field } from 'formik';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  FormGroup,
  Label,
  Col,
  Input,
  Button
} from 'reactstrap';
import * as Yup from 'yup';
import toastr from 'toastr';
import API2 from '../../../api2';
import LoadingModal from '../../../components/utils/LoadingModal';

export default function SaveCampaignModal(props) {
  const { isOpen, setOpen, onSave, workingData } = props;

  const [isSavingCampaign, setSavingCampaign] = useState(false);

  const saveCampaign = workingData => {
    setSavingCampaign(true);

    API2.post('audit', 'campaign', { ...workingData }, (err, res) => {
      setSavingCampaign(false);
      if (err) {
        console.log('err', err);
        toastr.error(`${i18n.t('error_saving_campaign')}: ${err}`);
      } else {
        onSave(res);
        toastr.success(`${i18n.t('ok_saving_campaign')}`);
      }
    });
  };

  return (
    <>
      <LoadingModal isOpen={isSavingCampaign} text={i18n.t('campaign_saving')} />
      <Modal isOpen={isOpen} toggle={() => setOpen(false)} className="modalFadeInScale">
        <ModalHeader toggle={() => setOpen(false)}>
          {i18n.t('planner_save_campaign_title')}
        </ModalHeader>
        <Formik
          initialValues={{ campaignName: workingData.campaignName || '' }}
          validationSchema={Yup.object().shape({
            campaignName: Yup.string()
              .min(4, i18n.t('error_too_short'))
              .max(100, i18n.t('error_too_long'))
              .required(i18n.t('error_required'))
          })}
          onSubmit={formValues => {
            workingData.campaignName = formValues.campaignName;
            saveCampaign(workingData);
            setOpen(false);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <ModalBody>
                {i18n.t('planner_save_campaign_text')}

                <br />
                <br />
                <FormGroup row>
                  <Label for="campaignName" sm={6}>
                    {i18n.t('campaign_name')}
                  </Label>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="campaignName"
                      tag={Field}
                      invalid={errors.campaignName && touched.campaignName}
                    />
                    <FormFeedback tooltip>{errors.campaignName}</FormFeedback>
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit" disabled={isSavingCampaign}>
                  {i18n.t('save_button')}
                </Button>{' '}
                <Button color="secondary" onClick={() => setOpen(false)}>
                  {i18n.t('cancel_button')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
