import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';

export default function ResultTable({ results }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="mb-2" style={{ width: '75%', minWidth: '75%' }}>
            {i18n.t('audit_result_description')}
          </th>
          <th className="mb-2" style={{ width: '25%', minWidth: '25%' }}>
            {i18n.t('audit_result_value')}
          </th>
        </tr>
      </thead>
      <tbody>
        {results ? (
          <>
            <tr>
              <td>{i18n.t('audit_total_impacted')}</td>
              <td>{numeral(results.total_impacted).format('0,0')}</td>
            </tr>
            <tr>
              <td>{i18n.t('audit_total_impacted_visitors')}</td>
              <td>{numeral(results.total_impacted_visitors).format('0,0')}</td>
            </tr>
            <tr>
              <td>{i18n.t('audit_conv_rate')}</td>
              <td>{numeral(results.conv_rate / 100).format('0.00%')}</td>
            </tr>
            <tr>
              <td>{i18n.t('audit_uplift')}</td>
              <td>{numeral(results.uplift / 100).format('0.00%')}</td>
            </tr>
            <tr>
              <td>{i18n.t('audit_total_oohs')}</td>
              <td>{numeral(results.total_oohs).format('0,0')}</td>
            </tr>
            <tr>
              <td>{i18n.t('audit_total_pois')}</td>
              <td>{numeral(results.total_pois).format('0,0')}</td>
            </tr>
          </>
        ) : null}
      </tbody>
    </table>
  );
}
