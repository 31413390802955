import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { Card, CardBody, CardTitle, Row, CardGroup } from 'reactstrap';
import VisitorsChart from '../../dashboard/VisitorsChart';
import VisitsCard from './VisitsCard';

export default function VisitorsCard({ responseVisitors }) {
  // let visitorsResponse = null;
  // let summaryVisits = null;

  // if (responseVisitors.hasValue('auditVisitsPeriod'))
  //   visitorsResponse = responseVisitors.data.value.auditVisitsPeriod;

  // if (responseVisitors.hasValue('auditSummaryVisits'))
  //   summaryVisits = responseVisitors.data.value.auditSummaryVisits;

  const visitorsResponse = responseVisitors.auditVisitsPeriod;
  const summaryVisits = responseVisitors.auditSummaryVisits;

  return (
    <>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">{i18n.t('audit_visitors_title')}</CardTitle>
              <div className="small mb-4 card-subtitle">{i18n.t('audit_visitors_subtitle')}</div>
              <div style={{ width: '100%', height: '400px' }}>
                {visitorsResponse && visitorsResponse.length > 0 ? (
                  <VisitorsChart
                    visitsTitle="total_visits_title"
                    uniquesTitle="total_unique_visitors_title"
                    visitorsAndVisitsResponse={visitorsResponse}
                    fixedPeriods="false"
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card mb-12">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('audit_visitors_title')}
                </CardTitle>
                <div className="small mb-4 card-subtitle">{i18n.t('audit_visitors_subtitle')}</div>
                <Row>
                  <div className="col-sm-4 col-md-4">
                    <VisitsCard
                      title={i18n.t('unique_visitors_title')}
                      value={
                        summaryVisits && summaryVisits.unique
                          ? numeral(summaryVisits.unique).format('0,0')
                          : '--'
                      }
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <VisitsCard
                      title={i18n.t('avg_dwell_time_title')}
                      value={
                        summaryVisits && summaryVisits.dwell_time_mins
                          ? `${numeral(summaryVisits.dwell_time_mins).format('00:00:00')} hs`
                          : '--'
                      }
                    />
                  </div>
                  <div className="col-sm-4 col-md-4">
                    <VisitsCard
                      title={i18n.t('new_visitors_title')}
                      value={
                        summaryVisits && summaryVisits.new_visitors
                          ? numeral(summaryVisits.new_visitors).format('0,0')
                          : '--'
                      }
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
    </>
  );
}
