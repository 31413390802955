import React from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';
import './radar.scss';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';
import { useDashboardData } from '../../hooks/metrics/esdata';

import { filterSelector } from '../../redux/ducks/filters';
export default function Radar(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const { responseRadar } = useDashboardData(typeFilter, {
    qRadar: true
  });

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('radar_title')}
        subtitle={i18n.t(poiType === 'ooh' ? 'radar_text_ooh' : 'radar_text')}
      />
      <ViewContent filter={typeFilter} responseRadar={responseRadar} poiType={poiType} />
    </div>
  );
}
