import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
// import moment from 'moment';

export default function DataValueTooltip({
  payload,
  active,
  customLabel,
  format = 'text',
  valueField = 'value',
  dateFormat = 'YYYY-MM-DD'
}) {
  if (active && payload && payload[0]) {
    let out;

    if (customLabel === 'events') {
      // console.log(payload);
    }

    if (format === 'text') {
      out = payload[0].value;
    } else if (format === 'number') {
      out = numeral(payload[0].value).format(payload[0].value > 10000000 ? '0.00a' : '0,0');
    } else if (format === 'decimal') {
      out = numeral(payload[0].value).format('0,0.00');
    } else if (format === 'percentage') {
      out = numeral(payload[0].value).format('%0,0');
    } else if (format === 'percentage_dec') {
      out = numeral(payload[0].value).format('%0,0.0');
    } else if (format === 'currency') {
      out = numeral(payload[0].value).format('$ 0,0.00');
    } else if (format === 'hour') {
      out = numeral(payload[0].value).format('00:00:00');
    }

    return (
      <div
        style={{
          padding: '3px 7px',
          background: 'rgba(60,70,75,.9)',
          fontSize: 9,
          color: '#fff',
          borderRadius: 2
        }}
      >
        {moment(payload[0].payload._id.period_quarter_start).utc().format(dateFormat)} -{' '}
        {moment(payload[0].payload._id.period_quarter_end).utc().format(dateFormat)}
        <br />
        {out} {customLabel}
      </div>
    );
  }
  return null;
}
