import React from 'react';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';
import JourneyTable from './JourneyTable';
import JourneyChart from './JourneyChart';

export default function JourneyCard({ response, dataKey, format, suffix = '' }) {
  if (!response || !response.length > 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  return (
    <div className="d-flex">
      {/* TABLE */}
      <Card className="col-6 col-md-6 col-sm-12 mb-4" style={{ height: '450px' }}>
        <JourneyTable response={response} dataKey={dataKey} format={format} suffix={suffix} />
      </Card>
      {/* CHART */}
      <Card className="ml-2 col-6 col-md-6 col-sm-12 pb-3" style={{ height: '450px' }}>
        <JourneyChart response={response} dataKey={dataKey} format={format} suffix={suffix} />
      </Card>
    </div>
  );
}
