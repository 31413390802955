import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { ResponseLoading } from '../../components/utils/Loading';
import RankingChart from './RankingChart';
import { colors } from '../../components/charts/Styles';
import StatsChart from '../dashboard/StatsChart';

export default function ViewContent({ filter, responseRanking }) {
  let rankingOOH = null;
  let rankingPDV = null;
  let rankingPDVByGroup = null;
  let rankingAttributionOOH = null;
  let rankingAttributionPDV = null;

  let data = 0;

  if (responseRanking.data.value) {
    rankingOOH = responseRanking.data.value.ranking ? responseRanking.data.value.ranking.ooh : null;
    rankingPDV = responseRanking.data.value.ranking ? responseRanking.data.value.ranking.pdv : null;
    rankingPDVByGroup = responseRanking.data.value.ranking
      ? responseRanking.data.value.ranking.pdvByGroup
      : null;
    rankingAttributionOOH = responseRanking.data.value.ranking
      ? responseRanking.data.value.ranking.attribution_ooh
      : null;
    rankingAttributionPDV = responseRanking.data.value.ranking
      ? responseRanking.data.value.ranking.attribution_pdv
      : null;
    if (rankingOOH) data += rankingOOH.length;
    if (rankingPDV) data += rankingPDV.length;
    if (rankingPDVByGroup) data += rankingPDVByGroup.length;
    if (rankingAttributionOOH) data += rankingAttributionOOH.length;
    if (rankingAttributionPDV) data += rankingAttributionPDV.length;
  }

  return (
    <div className="view-content">
      <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <CardTitle className="text-uppercase h6">
              <ResponseLoading response={responseRanking} />
            </CardTitle>
            {data === 0 ? <div className="text_check">{i18n.t('no_data')}</div> : null}
            {rankingOOH && rankingOOH.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_ooh_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_ooh_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingOOH} color={colors[1]} type="ooh" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingPDV && rankingPDV.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_pdv_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_pdv_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingPDV} color={colors[3]} type="pdv" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingPDVByGroup && rankingPDVByGroup.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_pdv_by_group_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_pdv_by_group_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '250px' }}>
                        <StatsChart response={rankingPDVByGroup} dataKey="perc" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingAttributionOOH && rankingAttributionOOH.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_attribution_ooh_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_attribution_ooh_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart
                          response={rankingAttributionOOH}
                          color={colors[1]}
                          type="ooh"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
            {rankingAttributionPDV && rankingAttributionPDV.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_attribution_pdv_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_attribution_pdv_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart
                          response={rankingAttributionPDV}
                          color={colors[3]}
                          type="pdv"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : null}
          </CardBody>
        </Card>
      </CardGroup>
    </div>
  );
}
