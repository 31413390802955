import i18n from 'i18n-js';

// Used these as reference for building the file https://github.com/beamworks/react-csv-importer/blob/master/src/locale/index.ts

export const getCustomLocale = () => ({
  general: {
    goToPreviousStepTooltip: i18n.t('csv_importer_general_goToPreviousStepTooltip')
  },

  fileStep: {
    initialDragDropPrompt: i18n.t('csv_importer_fileStep_initialDragDropPrompt'),
    activeDragDropPrompt: i18n.t('csv_importer_fileStep_activeDragDropPrompt'),

    getImportError: message => `${i18n.t('csv_importer_fileStep_getImportError')}: ${message}`,
    getDataFormatError: message =>
      `${i18n.t('csv_importer_fileStep_getDataFormaterror')}: ${message}`,
    goBackButton: i18n.t('csv_importer_fileStep_goBackButton'),
    nextButton: i18n.t('csv_importer_fileStep_nextButton'),

    rawFileContentsHeading: i18n.t('csv_importer_fileStep_rawFileContentsHeadings'),
    previewImportHeading: i18n.t('csv_importer_fileStep_previewImportHeading'),
    dataHasHeadersCheckbox: i18n.t('csv_importer_fileStep_dataHasHeadersCheckbox'),
    previewLoadingStatus: i18n.t('csv_importer_fileStep_previewLoadingstatus')
  },

  fieldsStep: {
    stepSubtitle: i18n.t('csv_importer_fieldStep_stepSubtitle'),
    requiredFieldsError: i18n.t('csv_importer_fieldStep_requiredFieldsError'),
    nextButton: i18n.t('csv_importer_fieldStep_nextButton'),

    dragSourceAreaCaption: i18n.t('csv_importer_fieldStep_dragSourceAreaCaption'),
    getDragSourcePageIndicator: (currentPage, pageCount) =>
      `${i18n.t('csv_importer_fieldStep_page')} ${currentPage} ${i18n.t(
        'csv_importer_fieldStep_of'
      )} ${pageCount}`,
    getDragSourceActiveStatus: columnCode =>
      `${i18n.t('csv_importer_fieldStep_getDragsourceActiveStatus')} ${columnCode}`,
    nextColumnsTooltip: i18n.t('csv_importer_fieldStep_nextColumnsTooltip'),
    previousColumnsTooltip: i18n.t('csv_importer_fieldStep_previousColumnsTooltip'),
    clearAssignmentTooltip: i18n.t('csv_importer_fieldStep_clearAssignmentTooltip'),
    selectColumnTooltip: i18n.t('csv_importer_fieldStep_selectColumnTooltip'),
    unselectColumnTooltip: i18n.t('csv_importer_fieldStep_unselectColumnTooltip'),

    dragTargetAreaCaption: i18n.t('csv_importer_fieldStep_dragTargetAreaCaption'),
    getDragTargetOptionalCaption: field => `${field} ${i18n.t('csv_importer_fieldStep_optional')}`,
    getDragTargetRequiredCaption: field =>
      `${field} (${i18n.t('csv_importer_fieldStep_required')})`,
    dragTargetPlaceholder: i18n.t('csv_importer_fieldStep_dragTargetPlaceholder'),
    getDragTargetAssignTooltip: columnCode =>
      `${i18n.t('csv_importer_fieldStep_getDragTargetAssigntooltip')} ${columnCode}`,
    dragTargetClearTooltip: i18n.t('csv_importer_fieldStep_dragTargetClearTooltip'),

    columnCardDummyHeader: i18n.t('csv_importer_fieldStep_columnCardDummyHeader'),
    getColumnCardHeader: code => `${i18n.t('csv_importer_fieldStep_getColumnCardHeader')} ${code}`
  },

  progressStep: {
    stepSubtitle: i18n.t('csv_importer_progressStep_stepSubtitle'),
    uploadMoreButton: i18n.t('csv_importer_progressStep_uploadMorebutton'),
    finishButton: i18n.t('csv_importer_progressStep_finshButton'),
    statusError: i18n.t('csv_importer_progressStep_statusError'),
    statusComplete: i18n.t('csv_importer_progressStep_statusComplete'),
    statusPending: i18n.t('csv_importer_progressStep_statusPending'),
    processedRowsLabel: i18n.t('csv_importer_progressStep_processedRowsLabel')
  }
});
