import React from 'react';
import i18n from 'i18n-js';

import { Row } from 'reactstrap';
import MetricFDCard from './MetricFDCard';
import { colors } from '../../components/charts/Styles';

export default function VisitsMetricsFDCard({ fullDemosObj, poiType }) {
  // if (!response || !response.data || !response.data.value) return null;

  // const responsePlatform = useStats('platform', filter);
  // console.log("responsePlatform", responsePlatform)

  return (
    <Row>
      <div className={poiType === 'ooh' ? 'col-sm-6 col-md-6' : 'col-sm-4 col-md-4'}>
        <MetricFDCard
          fullDemosObj={fullDemosObj}
          title={i18n.t(poiType === 'ooh' ? 'repeat_visitors_title_ooh' : 'repeat_visitors_title')}
          text={i18n.t(poiType === 'ooh' ? 'repeat_visits_text_ooh' : 'repeat_visits_text')}
          dataKey="repeat_visitors"
          totalKey="repeatVisitors"
          format="0.[00]a"
          formatBlock="number"
          stroke={colors[1]}
          fill={colors[1]}
        />
      </div>
      <div className={poiType === 'ooh' ? 'col-sm-6 col-md-6' : 'col-sm-4 col-md-4'}>
        <MetricFDCard
          fullDemosObj={fullDemosObj}
          title={i18n.t(poiType === 'ooh' ? 'new_visitors_title_ooh' : 'new_visitors_title')}
          text={i18n.t(poiType === 'ooh' ? 'new_visitors_text_ooh' : 'new_visitors_text')}
          dataKey="new_visitors"
          totalKey="newVisitors"
          format="0.[00]a"
          formatBlock="number"
          stroke={colors[2]}
          fill={colors[2]}
        />
      </div>
      {/* <div className="col-sm-6 col-md-3">
        <MetricFDCard
          fullDemosObj={fullDemosObj}
          title={i18n.t('visitors_ratio_title')}
          text={i18n.t('visitors_ratio_text')}
          dataKey="visitsRatio"
          format="% 0,0.0"
          formatBlock="percentage_dec"
          stroke={colors[3]}
          fill={colors[3]}
        />
      </div> */}
      {poiType === 'ooh' ? null : (
        <div className="col-sm-4 col-md-4">
          <MetricFDCard
            fullDemosObj={fullDemosObj}
            title={i18n.t('avg_dwell_time_title')}
            text={i18n.t('avg_dwell_time_text')}
            dataKey="dwell_time_mins"
            // ).format('00:00:00')} hs`
            format="00:00:00"
            formatBlock="hour"
            appendText=" hs"
            stroke={colors[4]}
            fill={colors[4]}
          />
        </div>
      )}
    </Row>
  );
}
