import React from 'react';

import MapCard from '../dashboard/MapCard';

export default function ViewContent({ responseHeatmap, poiType }) {
  return (
    <div className="view-content view-dashboard">
      <MapCard responseHeatmap={responseHeatmap} poiType={poiType} />
    </div>
  );
}
