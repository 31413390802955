import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import uniq from 'lodash/uniq';

import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';
import { useDashboardData } from '../../hooks/metrics/esdata';
import { defaultResponse } from '../../hooks/api';

import CompareColumn from './CompareColumn';
import { ResponseLoading } from '../../components/utils/Loading';
// import FlowMap from '../../components/heatmap/FlowMap';
import RadarMap from '../../components/heatmap/RadarMap';

const _defaultResponse = {
  ...defaultResponse,
  data: {
    ...defaultResponse.data,
    state: 'LOADED'
  }
};

const defaultResponseBase = {
  responseVisits: { ..._defaultResponse },
  responseDemos: { ..._defaultResponse }
};

const arrLength = 3;

export default function ViewContent({ defaultFilter, setFilterValues }) {
  // const [filterMaps, setFilterMap] = useState({ ...defaultFilter });
  const [responseBase, setResponseBase] = useState({ ...defaultResponseBase });
  const [columns, setColumns] = useState(null);
  const [maps, setMaps] = useState(null);

  const filterMaps = {
    period: defaultFilter.period,
    startDate: defaultFilter.startDate,
    endDate: defaultFilter.endDate,
    offset: defaultFilter.offset,
    groupKeys: defaultFilter.groupKeys,
    type: defaultFilter.type
  };

  const onFilterChange = React.useCallback(
    (column, segment) => {
      // set new value for column
      const columns = {
        ...defaultFilter.columns
      };

      if (segment) {
        columns[column] = segment;
      } else {
        // remove
        delete columns[column];
      }

      // recalculate groupKeys based on new columns
      const groupKeys = uniq(Object.keys(columns).map(k => columns[k].group_hash));

      setTimeout(() => {
        setFilterValues({ groupKeys, columns });
      }, 100);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultFilter.columns, setFilterValues]
  );

  const onResponseChange = React.useCallback((column, response) => {
    if (column === '0') {
      // setTimeout(() => {
      setResponseBase({ ...response });
      // }, 1);
    }
  }, []);

  useEffect(() => {
    const c = [
      <CompareColumn
        key="0"
        defaultFilter={defaultFilter}
        onResponseChange={onResponseChange}
        onFilterChange={onFilterChange}
        name="0"
        defaultResponse={_defaultResponse}
      />
    ];

    for (let i = 0; i < arrLength; i++) {
      c.push(
        <CompareColumn
          key={i + 1}
          defaultFilter={defaultFilter}
          onResponseChange={onResponseChange}
          onFilterChange={onFilterChange}
          responseBase={responseBase}
          name={`${i + 1}`}
          defaultResponse={_defaultResponse}
        />
      );
    }

    setColumns(c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseBase, defaultFilter]);

  useEffect(() => {
    if (columns) {
      const m = <CompareMaps filterMaps={filterMaps} />;
      setMaps(m);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMaps.groupKey, columns]);

  return (
    <>
      <div className="view-content view-compare view-columns">{columns}</div>

      {maps}
    </>
  );
}

function CompareMaps({ filterMaps }) {
  const [radarMap, setRadarMap] = useState(null);
  // const [flowMap, setFlowMap] = useState(null);

  const isFiltering = filterMaps && filterMaps.groupKeys && filterMaps.groupKeys.length > 0;
  const poiType = filterMaps.type;

  const { responseRadar } = useDashboardData(filterMaps, {
    qRadar: true,
    skip: !isFiltering
  });

  // const { responseHeatmap } = useDashboardData(filterMaps, {
  //   qHeatmap: true,
  //   skip: !isFiltering
  // });

  useEffect(() => {
    if (responseRadar && responseRadar.hasValue('heatmap')) {
      const m = <RadarMap {...{ stores: responseRadar.data.value.heatmap.stores }} />;
      setRadarMap(m);
    }
  }, [responseRadar]);

  // useEffect(() => {
  //   if (responseHeatmap && responseHeatmap.hasValue('heatmap')) {
  //     const m = <FlowMap responseHeatmap={responseHeatmap} poiType={poiType} />;
  //     setFlowMap(m);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [responseHeatmap]);

  return (
    <>
      <div className="view-content view-compare mt-0">
        <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('radar_title')}
                <ResponseLoading response={responseRadar} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(poiType === 'ooh' ? 'by_ooh' : 'by_poi')}
              </div>
              <div style={{ width: '100%', height: '600px' }}>
                {radarMap ? radarMap : <div className="text_check">{i18n.t('no_data')}</div>}
              </div>
            </CardBody>
          </Card>
        </CardGroup>
      </div>
      {/* <div className="view-content view-compare mt-0">
        <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('flow_map')}
                <ResponseLoading response={responseHeatmap} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(poiType === 'ooh' ? 'by_ooh' : 'by_poi')}
              </div>
              <div style={{ width: '100%', height: '600px' }}>
                {flowMap ? flowMap : <div className="text_check">{i18n.t('no_data')}</div>}
              </div>
            </CardBody>
          </Card>
        </CardGroup>
      </div> */}
    </>
  );
}
