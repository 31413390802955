import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle } from 'reactstrap';
import PopoverHelp from '../../components/utils/PopoverHelp';
import BlocksChart from '../../components/charts/BlocksChart';

export default function MetricFDCard({
  fullDemosObj,
  title,
  text,
  dataKey,
  format,
  formatBlock,
  stroke,
  totalKey,
  fill,
  appendText
}) {
  const valCurrent =
    fullDemosObj.metricsWeekly.reduce((accum, val) => accum + val[dataKey], 0) /
    fullDemosObj.metricsWeekly.length;

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle className="text-uppercase small font-weight-bold" style={{ minHeight: 24 }}>
          {title}
          {/* <ResponseLoading response={response} /> */}
          <PopoverHelp title={title} text={text} />
        </CardTitle>
        {totalKey ? (
          <>
            <div className="small mb-0 card-subtitle">{i18n.t('total')}</div>
            <div className="d-flex align-items-center">
              <h3 className="mr-2 font-weight-normal">
                {valCurrent > -1 ? numeral(fullDemosObj[totalKey]).format(format) : '--'}
              </h3>
              ({numeral(fullDemosObj[totalKey] / fullDemosObj.uniques).format('% 0.00')})
            </div>
          </>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
        <br />
        <div className="small mb-0 card-subtitle">
          {i18n.t(window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? 'avg_period' : 'avg')}
        </div>
        <div className="d-flex align-items-center">
          <h3 className="mr-2 font-weight-normal">
            {valCurrent > -1 ? numeral(valCurrent).format(format) : '--'}
            {appendText}
          </h3>
          {/* {valCurrent > 0 ? <VariationBlock variation={valCurrent / valPrev} /> : null} */}
        </div>
      </CardBody>
      <div style={{ width: '100%', height: '60px' }}>
        {' '}
        {fullDemosObj.metricsWeekly ? (
          <BlocksChart
            data={fullDemosObj.metricsWeekly}
            dataKey={dataKey}
            stroke={stroke}
            fill={fill}
            format={formatBlock}
          />
        ) : null}
      </div>
    </Card>
  );
}
