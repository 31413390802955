import React from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';
import { useMappedState } from 'redux-react-hook';
import { useAPIDataLoader } from '../../hooks/api';
import ViewContentBuilder from './ViewContentBuilder';
import { filterSelector } from '../../redux/ducks/filters';

export default function AudienceEdit(props) {
  const { filter } = useMappedState(filterSelector);

  const audienceResponse = useAPIDataLoader('audiences.getOne', [props.match.params.id]);

  if (audienceResponse.error) {
    console.log('error', audienceResponse.error);
    if (audienceResponse.error.response && audienceResponse.error.response.data) {
      toastr.error(`Error: ${audienceResponse.error.response.data.message}`);
    }
  }

  if (!audienceResponse || !audienceResponse.data || !audienceResponse.data.value) {
    return <div className="text_check">{i18n.t('audience_loading')}</div>;
  }

  const workingFilter = audienceResponse.data.value.filter;

  return (
    <div className="view">
      <div className="view-header d-flex">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('audience_title')}</h1>
          <p className="mb-0">{i18n.t('audience_details')}</p>
        </header>
        <div className="ml-auto d-sm-flex d-none mt-2">
          <div className="d-none d-md-block pt-3">
            {/* <Button href={`/audiences/datafeed/${props.match.params.id}`} color="success">
              Request Data Feed
            </Button>{' '}
            <Button href={`/audiences/activate/${props.match.params.id}`} color="success">
              Activate with Partner
            </Button> */}
          </div>
        </div>
      </div>
      <ViewContentBuilder filter={filter} workingFilter={workingFilter} />
    </div>
  );
}
