import React, { useState } from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import { StepButton } from './StepButton';

export function PeriodStep(props) {
  const [disabled, setDisabled] = useState(true);

  const { setCampaignValues } = props;

  const onApplyRange = value => {
    if (value && value.length > 0) {
      const startDate = moment(value[0]).startOf('day').toDate();
      const endDate = moment(value[1]).startOf('day').toDate();

      setCampaignValues({
        period: {
          startDate,
          endDate,
          offset: props.filter.offset
        }
      });
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <div>
      <div className="col-12 p-10 pl-0">
        <h6 className="subtitle pt-2">{i18n.t('audit_campaign_period')}</h6>

        <DateRangePicker
          appearance="default"
          block
          size="lg"
          renderValue={value =>
            `${moment(value[0]).format('MMMM DD')} - ${moment(value[1]).format('MMMM DD')}`
          }
          ranges={[]}
          onChange={onApplyRange}
          cleanable
        />
      </div>
      <StepButton {...props} step={props.step} disabled={disabled} />
    </div>
  );
}
