import React from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';
import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';
import { useDashboardData } from '../../../hooks/metrics/esdata';
import Logo from '../Logo';
import { filterSelector } from '../../../redux/ducks/filters';

export default function Sentiment(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const { responseRadar } = useDashboardData(typeFilter, {
    qRadar: true
  });

  const filterComponent = (
    <div className="m-auto text-center small text-white">
      Powered by <br />
      <Logo brand="datappeal" file="logo-premium.png" alt="Datappeal" />
    </div>
  );

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('premium_sentiment_title')}
        subtitle={i18n.t('premium_sentiment_text')}
        filterComponent={filterComponent}
        filterComponentSize="4"
      />
      <ViewContent responseRadar={responseRadar} />
    </div>
  );
}
