import React from 'react';

import { useMappedState } from 'redux-react-hook';

import './dashboard.scss';

import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useDashboardData, useFullDemosObj } from '../../hooks/metrics/esdata';
import { ExportExcelPanel } from '../../components/utils/ExportExcel';
import { filterSelector } from '../../redux/ducks/filters';

export default function Dashboard(props) {
  const { poiType } = props;
  const { filter } = useMappedState(filterSelector);
  const typeFilter = filter[poiType];

  const {
    responseVisits,
    responseDemos,
    responseTech,
    responseAppBundles,
    responseHeatmap,
    responseFullDemos
  } = useDashboardData(typeFilter, {
    qVisits: true,
    qDemos: true,
    qTech: true,
    qAppBundles: true,
    qHeatmap: true,
    qFullDemos: true
  });

  const filterComponent = (
    <ExportExcelPanel {...{ responseFullDemos, responseVisits, filter: typeFilter }} />
  );

  const fullDemosObj = useFullDemosObj(responseFullDemos, responseVisits);

  return (
    <div className="view">
      <React.StrictMode>
        <ViewHeader {...{ filterComponent }} />
        <ViewContent
          {...{
            filter: typeFilter,
            responseVisits,
            responseDemos,
            responseTech,
            responseAppBundles,
            responseHeatmap,
            fullDemosObj,
            type: props.user.type,
            poiType
          }}
        />
      </React.StrictMode>
    </div>
  );
}
