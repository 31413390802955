import React, { useState } from 'react';
import { CardGroup, Card, CardBody } from 'reactstrap';
import StepWizard from 'react-step-wizard';
import { PeriodStep } from './PeriodStep';
import { POIStep } from './POIStep';
import history from '../../../../history';
import SaveCampaignModal from './SaveCampaignModal';
import { ConfigStep } from './ConfigStep';

export default function ViewContent(props) {
  const [openCampaign, setOpenCampaign] = useState(false);

  // default values to load POIs
  const [campaignData, setCampaignData] = useState({});

  const setCampaignValues = vals => {
    setCampaignData(a => {
      return {
        ...a,
        ...vals
      };
    });
  };

  const resetData = () => {
    setCampaignData({});
  };

  const onSaveCampaign = () => {
    resetData();
    history.push('/audit');
  };

  return (
    <div className="view-content">
      <SaveCampaignModal
        isOpen={openCampaign}
        setOpen={setOpenCampaign}
        onSave={onSaveCampaign}
        workingData={campaignData}
      />

      <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <StepWizard isHashEnabled>
              <PeriodStep
                hashKey="Period"
                step={1}
                filter={props.filter}
                campaignData={campaignData}
                setCampaignValues={setCampaignValues}
              />
              <POIStep
                hashKey="OOH"
                poiType="ooh"
                step={2}
                filter={props.filter}
                campaignData={campaignData}
                setCampaignValues={setCampaignValues}
              />
              <POIStep
                hashKey="PDV"
                poiType="pdv"
                step={3}
                filter={props.filter}
                campaignData={campaignData}
                setCampaignValues={setCampaignValues}
              />
              <ConfigStep
                hashKey="Config"
                step={4}
                filter={props.filter}
                campaignData={campaignData}
                setCampaignValues={setCampaignValues}
                submit={() => setOpenCampaign(true)}
              />
            </StepWizard>
          </CardBody>
        </Card>
      </CardGroup>
    </div>
  );
}
