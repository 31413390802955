import i18n from 'i18n-js';
import React from 'react';
import Select from 'react-select';
import { CardTitle } from 'reactstrap';

export function SingleSelectFilter(
  title,
  field,
  list,
  onSelectParam,
  selectedValue = null,
  required = false,
  notifyRequired = null
) {
  if (notifyRequired) {
    notifyRequired(field, { required, title });
  }
  // requiredFields[field] = { required: required, title: title };
  return (
    <div key={field}>
      <CardTitle className="text-uppercase small font-weight-bold d-flex justify-content-between align-items-end">
        {title}{' '}
        {required && <span className="small card-subtitle">* {i18n.t('error_required')}</span>}
        {/* <ResponseLoading response={responseFilters} /> */}
      </CardTitle>
      <div style={{ width: '100%', height: '60px' }}>
        <Select
          value={selectedValue}
          options={list}
          onChange={v => onSelectParam(field, v)}
          isClearable
        />
      </div>
    </div>
  );
}

export function MultiSelectFilter(
  title,
  field,
  list,
  onSelectParam,
  selectedValue = null,
  required = false,
  notifyRequired = null
) {
  if (notifyRequired) {
    notifyRequired(field, required);
  }
  // requiredFields[field] = required;
  return (
    <div key={field}>
      <CardTitle className="text-uppercase small font-weight-bold">
        {title}
        {/* <ResponseLoading response={responseFilters} /> */}
      </CardTitle>
      <div style={{ width: '100%', height: '110px' }}>
        <Select
          styles={customStyles}
          isMulti
          isClearable={false}
          value={selectedValue}
          options={list}
          onChange={v => onSelectParam([field], v)}
          closeMenuOnSelect={!selectedValue || selectedValue.length < 1}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
        />
      </div>
    </div>
  );
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: '90px',
    maxHeight: '90px',
    overflow: 'scroll'
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: '10'
  })
};
