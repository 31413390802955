export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) return pair[1];
  }
  return null;
}

export function openNewWindow(path, keepVars = []) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  const k = [];

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    for (let h = 0; h < keepVars.length; h++) {
      if (pair[0] === keepVars[h]) {
        k.push(vars[i]);
      }
    }
  }
  window.open(`${path}?${k.join('&')}`, '_blank');
}

const e = {
  getQueryVariable,
  openNewWindow
};

export default e;
