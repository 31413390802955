import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer, Cell } from 'recharts';

import { colors } from '../../components/charts/Styles';

export default function AgeRangeChart({ response, onClick, maxValue }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const total = maxValue || response.reduce((accum, val) => val.value + accum, 0);

  return (
    <ResponsiveContainer>
      <BarChart
        layout="vertical"
        data={response}
        margin={{ top: 0, right: 35, left: 5, bottom: -10 }}
      >
        {/* <XAxis type="number" tickFormatter={value => numeral(value / total).format('%0')} /> */}
        <XAxis
          type="number"
          tickFormatter={value => numeral(value).format('0,0')}
          domain={[0, maxValue / 3]}
        />
        <YAxis
          tickFormatter={value =>
            // eslint-disable-next-line no-nested-ternary
            i18n.t(value)
          }
          dataKey="name"
          // interval={0}
          type="category"
        />
        {/* <Tooltip formatter={value => numeral(value / 100).format('%0,0.0')} /> */}
        {/* <Tooltip formatter={value => ` (${numeral(value / total).format('%0,0.0')})`} /> */}
        <Tooltip
          formatter={value =>
            `${numeral(value).format('0,0')} (${numeral(value / total).format('%0,0.0')})`
          }
        />
        <Bar onClick={onClick} dataKey="value" name={i18n.t('share')} barSize={30}>
          {response.map((entry, index) => (
            <Cell fill={entry.color || colors[index]} key={index} />
          ))}
        </Bar>
      </BarChart>

      {/*      <RadialBarChart
        width={500}
        height={300}
        cx={150}
        cy={150}
        innerRadius={20}
        outerRadius={140}
        barSize={10}
        data={response}
      >
        <RadialBar minAngle={15} background clockWise dataKey="value" />
        <LabelList position="insideEnd" fill="#fff" fontSize={10} />
        <Legend
          iconSize={10}
          width={120}
          height={140}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={style}
        />
        <Tooltip
          labelFormatter={position => response[position].name}
          labelStyle={{ fontWeight: 'bold' }}
          formatter={value => numeral(value / 100).format('% 0,0.00')}
        />
        />
      </RadialBarChart> */}
    </ResponsiveContainer>
  );
}
