import variables from '../../../styles/variables.module.scss';

export const colors = [
  variables.color0,
  variables.color1,
  variables.color2,
  variables.color3,
  variables.color4,
  variables.color5,
  variables.color6,
  variables.color7,
  variables.color8,
  variables.color9,
  variables.color10
];

const e = {
  colors
};

export default e;
