import React from 'react';
import { Rectangle, Layer } from 'recharts';
import numeral from 'numeral';

export default function SankeyNode({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth,
  colors
}) {
  const isOut = x + width + 6 > containerWidth;
  const s = payload.name.split(' ');
  const l = s.length;

  let name0;
  let name1;
  let name2;
  if (l > 4) {
    name0 = s.slice(0, l / 2).join(' ');
    name1 = s.slice(l / 2, l / 2 + 2).join(' ');
    name2 = s.slice(4).join(' ');
  } else if (l > 2) {
    name1 = s.slice(0, l / 2).join(' ');
    name2 = s.slice(l / 2).join(' ');
  } else {
    name2 = payload.name;
  }

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        // fill={colors[index % colors.length]}
        fill={colors[payload.color]}
        fillOpacity="1"
      />
      {name0 && (
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2 - 22}
          fontSize="11"
          fontWeight="normal"
          stroke="#333"
          strokeOpacity="0.25"
        >
          {name0}
        </text>
      )}
      {name1 && (
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2 - 11}
          fontSize="11"
          fontWeight="normal"
          stroke="#333"
          strokeOpacity="0.25"
        >
          {name1}
        </text>
      )}
      <text
        // transform="translate(0,35)"
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 1}
        fontSize="11"
        fontWeight="normal"
        stroke="#333"
        strokeOpacity="0.25"
      >
        {name2}
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="10"
        stroke="#333"
        strokeOpacity="0.25"
      >
        {/* {numeral(payload.value).format('0,00a')} ({numeral(payload.value / total).format('%0,0')}) */}
        {/* {numeral(payload.value / total).format('%0.00')} */}
        {numeral(payload.value).format('%0.00')}
      </text>
    </Layer>
  );
}
