import React, { useEffect } from 'react';
import Wave from 'react-wavify';
import './onboarding.css';

function OnboardingLayout(props) {
  useEffect(() => {
    document.body.style.backgroundColor = window._env_.REACT_APP_BRAND_COLOR_BODY;
  });

  return (
    <div>
      <div className="particles-container">
        <Wave
          fill={window._env_.REACT_APP_BRAND_COLOR_THIRD}
          paused={false}
          options={{
            height: 50,
            amplitude: 130,
            speed: 0.1,
            points: 6
          }}
          className="wave2"
        />
        <Wave
          fill={window._env_.REACT_APP_BRAND_COLOR_PRIMARY}
          paused={false}
          options={{
            height: 25,
            amplitude: 100,
            speed: 0.1,
            points: 7
          }}
          className="wave3"
        />
        <Wave
          fill={window._env_.REACT_APP_BRAND_COLOR_SECONDARY}
          paused={false}
          options={{
            height: 0,
            amplitude: 70,
            speed: 0.1,
            points: 8
          }}
          className="wave4"
        />
      </div>

      <div className="app-root">
        <div className="container">
          <div className="resize-margin">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingLayout;
