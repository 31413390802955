import React from 'react';
import i18n from 'i18n-js';
import { Card, Row, CardBody, CardTitle } from 'reactstrap';
import DashboardTopFDCard from './DashboardTopFDCard';
import VisitsTrendsCard from './VisitsTrendsCard';
import VisitsTrendsFDCard from './VisitsTrendsFDCard';
import DemographicsCard from './DemographicsCard';
import TechCard from './TechCard';
import VisitsMetricsFDCard from './VisitsMetricsFDCard';
import OfflineInterestsCard from './OfflineInterestsCard';
import VisitorsByDayCard from './VisitorsByDayCard';
import VisitorsByTimeBlock from './VisitorsByTimeBlockCard';

export default function ViewContent({
  filter,
  responseVisits,
  responseTech,
  responseAppBundles,
  fullDemosObj,
  poiType
}) {
  const isSingleView = filter.storeKey !== null;

  const groupCount = isSingleView
    ? 1
    : filter.pois && filter.pois.length > 0
    ? filter.pois[0].count
    : -1;

  const groupName = isSingleView
    ? responseVisits.hasValue('storeGroup')
      ? responseVisits.data.value.storeGroup.group_name
      : null
    : filter.pois && filter.pois.length > 0
    ? filter.pois[0].label
    : null;

  const groupPoiType =
    isSingleView && responseVisits.hasValue('storeGroup')
      ? responseVisits.data.value.storeGroup.type
      : poiType;

  const groupTitle = isSingleView
    ? i18n.t('mobility_trends_general_info_single', {
        type: i18n.t(`dashboard_${groupPoiType}`)
      })
    : i18n.t('mobility_trends_general_info');

  return (
    <div className="view-content view-dashboard">
      {isSingleView && responseVisits.hasValue('storeGroup') && (
        <Row>
          <div className="mb-4 col-sm-12 col-md-12">
            <Card>
              <CardBody>
                <CardTitle className="text-uppercase h6">{groupTitle}</CardTitle>
                <div className="mb-4 card-subtitle">{groupName}</div>
              </CardBody>
            </Card>
          </div>
        </Row>
      )}

      {window._env_.REACT_APP_MODULE_REPORTS_DAILY === 'true' && (
        <VisitorsByDayCard responseVisits={responseVisits} poiType={groupPoiType} />
      )}
      {window._env_.REACT_APP_MODULE_REPORTS_CUSTOM_TIME_BLOCKS === 'true' && (
        <VisitorsByTimeBlock
          responseVisits={responseVisits}
          fullDemosObj={fullDemosObj}
          poiType={groupPoiType}
        />
      )}
      <DemographicsCard fullDemosObj={fullDemosObj} />

      <DashboardTopFDCard
        filter={filter}
        fullDemosObj={fullDemosObj}
        groupCount={groupCount}
        poiType={groupPoiType}
      />

      <VisitsMetricsFDCard fullDemosObj={fullDemosObj} poiType={groupPoiType} />

      <VisitsTrendsFDCard fullDemosObj={fullDemosObj} poiType={groupPoiType} />

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <hr />
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">{groupTitle}</CardTitle>
              <div className="mb-4 card-subtitle">{groupName}</div>
            </CardBody>
          </Card>
        </div>
      </Row>

      <VisitsTrendsCard filter={filter} responseVisits={responseVisits} poiType={groupPoiType} />

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <hr />
        </div>
      </Row>

      <OfflineInterestsCard responseVisits={responseVisits} poiType={groupPoiType} />

      <TechCard
        responseAppBundles={responseAppBundles}
        responseTech={responseTech}
        poiType={groupPoiType}
      />
    </div>
  );
}
