import axios from 'axios';
import LRU from 'lru-cache';

import { clearFilter } from './filters';

// Actions

const AUTH_SET_LOGIN = 'auth/SET_LOGIN';
const AUTH_SET_LOGOUT = 'auth/SET_LOGOUT';
const AUTH_SET_USER = 'auth/SET_USER';
const AUTH_SET_ERROR = 'auth/AUTH_SET_ERROR';

export const metricsCache = new LRU({ max: 1000 });
// Reducer

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case AUTH_SET_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user
      };
    case AUTH_SET_LOGOUT:
      return {};
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case AUTH_SET_ERROR:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}

// Action Creators

export function authSetLogin(user, token) {
  return {
    type: AUTH_SET_LOGIN,
    payload: {
      user,
      token
    }
  };
}

export function authSetLogout() {
  return {
    type: AUTH_SET_LOGOUT
  };
}

export function authSetUser(user) {
  return {
    type: AUTH_SET_USER,
    payload: {
      user
    }
  };
}

export function authSetError(error) {
  return {
    type: AUTH_SET_ERROR,
    payload: {
      error
    }
  };
}

export function authClearError() {
  return {
    type: AUTH_SET_ERROR,
    payload: {
      error: null
    }
  };
}

export function logout() {
  return dispatch => {
    metricsCache.clear();
    delete axios.defaults.headers.common.Authorization;
    dispatch(authSetLogout());
    dispatch(clearFilter());
  };
}

export function login(email, password) {
  return dispatch => {
    dispatch(authSetError(null));

    axios
      .post('/v2/auth/login', {
        email: email.toLowerCase().trim(),
        password
      })
      .then(({ data }) => {
        const { accessToken } = data.token;

        const user = {
          id: data.user.id,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          role: data.user.role,
          roles: [data.user.role],
          type: data.user.type
        };

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        dispatch(clearFilter());
        dispatch(authSetLogin(user, data.token));
      })
      .catch(err => {
        dispatch(
          authSetError((err.response && err.response.data.message) || 'Something went wrong.')
        );
      });
  };
}

export function signup(params) {
  return dispatch => {
    dispatch(authSetError(null));

    axios
      .post('/v2/auth/register', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        password: params.password
      })
      .then(({ data }) => {
        const { accessToken } = data.token;

        const user = {
          id: data.user.id,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          role: data.user.role,
          roles: [data.user.role],
          type: data.user.type
        };

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        dispatch(authSetLogin(user, data.token));
      })
      .catch(err => {
        if (err.response && err.response.status === 409) {
          dispatch(authSetError('Email already exists'));
        } else {
          dispatch(
            authSetError((err.response && err.response.data.message) || 'Something went wrong.')
          );
          console.log('Signup error', err);
        }
      });
  };
}

export function refreshToken() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.auth.user) {
      const { refreshToken } = state.auth.token;
      const { email } = state.auth.user;

      axios
        .post('/v2/auth/refresh-token', { refreshToken, email })
        .then(({ data }) => {
          axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;

          dispatch(authSetLogin(state.auth.user, data));

          // eslint-disable-next-line
          location.reload();
        })
        .catch(err => {
          console.log('refresh-token err', err);
          dispatch(logout());
        });
    }
  };
}
