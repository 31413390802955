import React from 'react';
import i18n from 'i18n-js';

import { useDispatch, useMappedState } from 'redux-react-hook';

import './compare.scss';

import moment from 'moment';

import Select from 'react-select';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useAPIDataLoader } from '../../hooks/api';
import { ResponseLoading } from '../../components/utils/Loading';
import { setCompareFilter, filterSelector } from '../../redux/ducks/filters';
import CustomDatePicker from '../../components/filter/CustomDatePicker';

export default function Compare({ fullMonth, poiType }) {
  const dispatch = useDispatch();

  const { compareFilter } = useMappedState(filterSelector);

  const typeCompareFilter = compareFilter[poiType];
  const setCompareFilterDispath = filterValues => {
    const f = {
      ...typeCompareFilter,
      ...filterValues
    };

    dispatch(setCompareFilter(f));
  };

  // console.log(compareFilter);

  const responsePeriods = useAPIDataLoader(
    'periods.getPeriods',
    {},
    { cache: true, skip: window._env_.REACT_APP_TYPE_PERIOD !== 'fixed' }
  );
  if (responsePeriods.error) {
    console.log('getPeriods error', responsePeriods.error);
  }

  const onSelectPeriod = period => {
    setCompareFilterDispath({
      period
    });
  };

  const onApplyRange = React.useCallback(value => {
    const _startDate = fullMonth ? moment(value).startOf('month') : moment(value[0]);
    const _endDate = fullMonth ? moment(value).endOf('month') : moment(value[1]);
    const startDate = _startDate.startOf('day');
    const endDate = _endDate.startOf('day');

    // TODO: do we need this? test with 'week' configuration
    // force endDate to be the same week as startDate -> in Compare we only allow one week selection
    // const ed =
    //   window._env_.REACT_APP_TYPE_PERIOD === 'week'
    //     ? moment(typeFilter.startDate).endOf('isoWeek')
    //     : typeFilter.startDate;

    setCompareFilterDispath({
      startDate,
      endDate
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateFilterComponent = (
    <form
      className="col-12 col-sm-12 col-md-6 col-lg-6 p-10 site-search"
      onSubmit={e => e.preventDefault()}
    >
      {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? (
        <>
          <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
          <ResponseLoading response={responsePeriods} className="loading-dot-white" />
          <div style={{ width: '100%', height: '60px' }}>
            <Select
              value={typeCompareFilter.period}
              options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
              onChange={p => onSelectPeriod(p)}
              getOptionValue={option => option.oid}
            />
          </div>
        </>
      ) : (
        <>
          <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
          <CustomDatePicker
            fullMonth={fullMonth}
            value={typeCompareFilter ? moment(typeCompareFilter.startDate).toDate() : new Date()}
            valueRange={
              typeCompareFilter
                ? [
                    moment(typeCompareFilter.startDate).startOf('isoWeek').toDate(),
                    moment(typeCompareFilter.endDate).endOf('isoWeek').toDate()
                  ]
                : [new Date(), new Date()]
            }
            onChange={onApplyRange}
          />
        </>
      )}
    </form>
  );
  return (
    <div className="view">
      <React.StrictMode>
        <ViewHeader title={i18n.t('compare_menu')} filterComponent={dateFilterComponent} />
        <ViewContent defaultFilter={typeCompareFilter} setFilterValues={setCompareFilterDispath} />
      </React.StrictMode>
    </div>
  );
}
