import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import AdvisorCard from './AdvisorCard';
import RecommendationTable from './RecommendationTable';
import { colors } from '../../../components/charts/Styles';
import StatsChart from '../../dashboard/StatsChart';
import LineChart from '../../../components/charts/LineChart';
import BarChart from '../../../components/charts/BarChart';
import RadarChart from '../../../components/charts/RadarChart';
import SynchronizedAreaChart from '../../../components/charts/SynchronizedAreaChart';
import { useAPIDataLoader } from '../../../hooks/api';

export default function ViewContent() {
  const responseAdvisorData = useAPIDataLoader('ai.getAdvisorData', {}, { cache: true });
  if (responseAdvisorData.error) {
    console.log('getAdvisorData error', responseAdvisorData.error);
  }

  let KPI = null;
  let recommendations = null;
  let chartsData = null;
  let marketingData = null;
  let marketingData2 = null;
  let performanceData = null;
  let churnData = null;
  let competitionData = null;
  let revenueData = null;
  let costsData = null;

  if (responseAdvisorData.data.value) {
    KPI = responseAdvisorData.data.value.KPI;
    recommendations = responseAdvisorData.data.value.recommendations;
    chartsData = responseAdvisorData.data.value.chartsData;
    marketingData = responseAdvisorData.data.value.marketingData;
    marketingData2 = responseAdvisorData.data.value.marketingData2;
    performanceData = responseAdvisorData.data.value.performanceData;
    churnData = responseAdvisorData.data.value.churnData;
    competitionData = responseAdvisorData.data.value.competitionData;
    revenueData = responseAdvisorData.data.value.revenueData;
    costsData = responseAdvisorData.data.value.costsData;
  }

  const [view, setView] = useState('Marketing');
  function isViewing(v) {
    return view === v;
  }

  return (
    <div className="view-content">
      <Row>
        <div className="col-sm-4 col-md-4">
          <AdvisorCard
            title={i18n.t('ai_advisor_marketing')}
            text={i18n.t('ai_advisor_marketing_text')}
            kpi={KPI ? KPI.marketing : 0}
            selected={isViewing('Marketing')}
            format="$ 0.[00]a"
            recommendations={recommendations ? recommendations.marketing : []}
            dataChart={chartsData}
            dataKey="marketing"
            stroke={colors[0]}
            fill={colors[0]}
            formatBlock="number"
            callbackOnClick={() => {
              setView('Marketing');
            }}
          />
        </div>
        <div className="col-sm-4 col-md-4">
          <AdvisorCard
            title={i18n.t('ai_advisor_performance')}
            text={i18n.t('ai_advisor_performance_text')}
            kpi={KPI ? KPI.performance : 0}
            selected={isViewing('Performance')}
            format="% 0.00"
            recommendations={recommendations ? recommendations.performance : []}
            dataChart={chartsData}
            dataKey="performance"
            stroke={colors[1]}
            fill={colors[1]}
            formatBlock="number"
            callbackOnClick={() => {
              setView('Performance');
            }}
          />
        </div>
        <div className="col-sm-4 col-md-4">
          <AdvisorCard
            title={i18n.t('ai_advisor_churn')}
            text={i18n.t('ai_advisor_churn_text')}
            kpi={KPI ? KPI.churn : 0}
            selected={isViewing('Churn')}
            format="% 0.00"
            recommendations={recommendations ? recommendations.churn : []}
            dataChart={chartsData}
            dataKey="churn"
            stroke={colors[2]}
            fill={colors[2]}
            formatBlock="number"
            callbackOnClick={() => {
              setView('Churn');
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="col-sm-4 col-md-4">
          <AdvisorCard
            title={i18n.t('ai_advisor_competition')}
            text={i18n.t('ai_advisor_competition_text')}
            kpi={KPI ? KPI.competition : null}
            selected={isViewing('Competition')}
            format="% 0.00"
            recommendations={recommendations ? recommendations.competition : []}
            dataChart={chartsData}
            dataKey="competition"
            stroke={colors[3]}
            fill={colors[3]}
            formatBlock="number"
            callbackOnClick={() => {
              setView('Competition');
            }}
          />
        </div>
        <div className="col-sm-4 col-md-4">
          <AdvisorCard
            title={i18n.t('ai_advisor_revenue')}
            text={i18n.t('ai_advisor_revenue_text')}
            kpi={KPI ? KPI.revenue : 0}
            selected={isViewing('Revenue')}
            format="$ 0.[00]a"
            recommendations={recommendations ? recommendations.revenue : []}
            dataChart={chartsData}
            dataKey="revenue"
            stroke={colors[4]}
            fill={colors[4]}
            formatBlock="number"
            callbackOnClick={() => {
              setView('Revenue');
            }}
          />
        </div>
        <div className="col-sm-4 col-md-4">
          <AdvisorCard
            title={i18n.t('ai_advisor_costs')}
            text={i18n.t('ai_advisor_costs_text')}
            kpi={KPI ? KPI.costs : 0}
            selected={isViewing('Costs')}
            format="$ 0.[00]a"
            recommendations={recommendations ? recommendations.costs : []}
            dataChart={chartsData}
            dataKey="costs"
            stroke={colors[6]}
            fill={colors[6]}
            formatBlock="number"
            callbackOnClick={() => {
              setView('Costs');
            }}
          />
        </div>
      </Row>
      {isViewing('Marketing') ? (
        <Row>
          <div className="col-sm-12 col-md-12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle
                  className="text-uppercase medium font-weight-bold"
                  style={{ minHeight: 24 }}
                >
                  {i18n.t('ai_advisor_marketing')}
                </CardTitle>
                <RecommendationTable
                  recommendations={recommendations ? recommendations.marketing : []}
                />
              </CardBody>
              <Row className="align-items-center p-4 mb-4">
                <div style={{ height: '250px' }} className="col-md-6 col-sm-12 mb-4">
                  <StatsChart response={marketingData} dataKey="perc" />
                </div>
                <div style={{ height: '250px' }} className="col-md-6 col-sm-12 mt-4">
                  <RadarChart
                    data={marketingData2}
                    dataKeyX="category"
                    dataKeyY="male"
                    nameY="Male"
                    stroke={colors[1]}
                    stroke2={colors[4]}
                    dataKeyY2="female"
                    nameY2="Female"
                  />
                </div>
              </Row>
            </Card>
          </div>
        </Row>
      ) : null}
      {isViewing('Performance') ? (
        <Row>
          <div className="col-sm-12 col-md-12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle
                  className="text-uppercase medium font-weight-bold"
                  style={{ minHeight: 24 }}
                >
                  {i18n.t('ai_advisor_performance')}
                </CardTitle>
                <RecommendationTable
                  recommendations={recommendations ? recommendations.performance : []}
                />
              </CardBody>
              <div style={{ width: '100%', height: '550px' }}>
                <SynchronizedAreaChart
                  data={performanceData}
                  title="Visitors"
                  title2="Visits"
                  dataKeyX="range"
                  dataKeyY="projectedVisitors"
                  nameY="Projected Visitors"
                  stroke={colors[1]}
                  dataKeyY2="visitors"
                  nameY2="Visitors"
                  stroke2={colors[9]}
                  dataKeyY3="projectedVisits"
                  nameY3="Projected Visits"
                  stroke3={colors[2]}
                  dataKeyY4="visits"
                  nameY4="Visits"
                  stroke4={colors[9]}
                  format="0.[00]a"
                />
              </div>
            </Card>
          </div>
        </Row>
      ) : null}
      {isViewing('Churn') ? (
        <Row>
          <div className="col-sm-12 col-md-12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle
                  className="text-uppercase medium font-weight-bold"
                  style={{ minHeight: 24 }}
                >
                  {i18n.t('ai_advisor_churn')}
                </CardTitle>
                <RecommendationTable
                  recommendations={recommendations ? recommendations.churn : []}
                />
              </CardBody>
              <div style={{ width: '100%', height: '250px' }}>
                <LineChart
                  data={churnData}
                  dataKeyX="range"
                  dataKeyY="churn"
                  stroke={colors[1]}
                  dataKeyY2="projected"
                  stroke2={colors[2]}
                  format="% 0.00"
                />
              </div>
            </Card>
          </div>
        </Row>
      ) : null}
      {isViewing('Competition') ? (
        <Row>
          <div className="col-sm-12 col-md-12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle
                  className="text-uppercase medium font-weight-bold"
                  style={{ minHeight: 24 }}
                >
                  {i18n.t('ai_advisor_competition')}
                </CardTitle>
                <RecommendationTable
                  recommendations={recommendations ? recommendations.competition : []}
                />
              </CardBody>
              <div style={{ width: '100%', height: '250px' }}>
                <BarChart
                  data={competitionData}
                  dataKeyX="range"
                  dataKey="competition"
                  dataKey2="projected"
                  stroke={colors[4]}
                  stroke2={colors[1]}
                />
              </div>
            </Card>
          </div>
        </Row>
      ) : null}
      {isViewing('Revenue') ? (
        <Row>
          <div className="col-sm-12 col-md-12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle
                  className="text-uppercase medium font-weight-bold"
                  style={{ minHeight: 24 }}
                >
                  {i18n.t('ai_advisor_revenue')}
                </CardTitle>
                <RecommendationTable
                  recommendations={recommendations ? recommendations.revenue : []}
                />
              </CardBody>
              <div style={{ width: '100%', height: '250px' }}>
                <LineChart
                  data={revenueData}
                  dataKeyX="range"
                  dataKeyY="revenue"
                  stroke={colors[1]}
                  dataKeyY2="projected"
                  stroke2={colors[2]}
                  format="$ 0.[00]a"
                />
              </div>
            </Card>
          </div>
        </Row>
      ) : null}
      {isViewing('Costs') ? (
        <Row>
          <div className="col-sm-12 col-md-12">
            <Card className="mb-4">
              <CardBody>
                <CardTitle
                  className="text-uppercase medium font-weight-bold"
                  style={{ minHeight: 24 }}
                >
                  {i18n.t('ai_advisor_costs')}
                </CardTitle>
                <RecommendationTable
                  recommendations={recommendations ? recommendations.costs : []}
                />
              </CardBody>
              <div style={{ width: '100%', height: '250px' }}>
                <LineChart
                  data={costsData}
                  dataKeyX="range"
                  dataKeyY="cost"
                  stroke={colors[1]}
                  dataKeyY2="projected"
                  stroke2={colors[2]}
                  format="$ 0.[00]a"
                />
              </div>
            </Card>
          </div>
        </Row>
      ) : null}
    </div>
  );
}
