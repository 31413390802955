import React, { useState } from 'react';
import i18n from 'i18n-js';
import {
  MdDelete,
  MdFiberNew,
  MdOutlineCheck,
  MdOutlineTopic,
  MdVisibility,
  MdAutorenew
} from 'react-icons/md/';

import { Card, CardBody, Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import moment from 'moment';
import toastr from 'toastr';
import history from '../../../history';

import { useAPIv2DataLoader } from '../../hooks/api';
import API2 from '../../api2';

import Paginator from '../../components/pagination/Paginator';

import { Loading } from '../../components/utils/Loading';

import { Filter, SortIcon } from '../../components/filter/Filter';
import PopupConfirm from '../../components/utils/PopupConfirm';

function CampaignRow({ campaign }) {
  const deleteCampaign = () => {
    PopupConfirm({
      onConfirm: () => {
        // apiCall('campaigns.delete', [campaign._id]);
        // window.location.reload();

        API2.delete('audit', `campaign/${campaign._id}`, {}, (err, res) => {
          if (err) {
            console.log('err', err);
            toastr.error(`${i18n.t('error_saving_campaign')}: ${err}`);
          } else {
            toastr.success(`${i18n.t('ok_saving_campaign')}`);
            window.location.reload();
          }
        });
      }
    });
  };

  // const configsCampaign = () => {};

  const detailsCampaign = () => {
    history.push(`/audit/details/${[campaign._id]}`);
  };

  const periodLabel = () => {
    return `${moment(campaign.period.startDate).format('MMMM DD')} - ${moment(
      campaign.period.endDate
    ).format('MMMM DD')}`;
  };

  const statusIcon = () => {
    if (campaign.status === 'NEW') return <MdFiberNew size="18" />;
    if (campaign.status === 'IN_PROGRESS') return <MdAutorenew size="18" className="rotate" />;
    if (campaign.status === 'READY') return <MdOutlineCheck size="18" />;
    return null;
  };

  return (
    <tr>
      {/* <td>{periodLabel(campaign)}</td> */}
      <td>{campaign.campaignName}</td>
      <td>
        {campaign.ooh.label} ({campaign.ooh.count})
      </td>
      <td>
        {campaign.pdv.label} ({campaign.pdv.count})
      </td>
      <td className="text-center">{statusIcon()}</td>
      <td>{moment(campaign.createdAt).format('YY-MM-DD HH:mm:ss')}</td>
      <td>{campaign.user.email}</td>
      <td className="d-flex justify-content-end">
        {campaign.status === 'READY' ? (
          <MdVisibility
            size="18"
            color="#363537"
            onClick={detailsCampaign}
            style={{ cursor: 'pointer' }}
            // title={i18n.t('audit_actions_delete')}
            className="mr-2"
          />
        ) : null}

        {/* TODO: make this nicer -> remove popover */}
        {campaign.config && (
          <PopoverConfig title={campaign.campaignName}>
            <div className="d-flex">
              <span className="col-6 text-right mr-2 p-0 text-nowrap">
                {i18n.t('audit_campaign_period')}:
              </span>
              <span className="font-weight-bold">{periodLabel()}</span>
            </div>
            <div className="d-flex">
              <span className="col-6 text-right mr-2 p-0 text-nowrap">
                {i18n.t('audit_config_ooh_radius')}:
              </span>
              <span className="font-weight-bold">{campaign.config.radiusOOH} mts</span>
            </div>
            <div className="d-flex">
              <span className="col-6 text-right mr-2 p-0 text-nowrap">
                {i18n.t('audit_config_pdv_radius')}:
              </span>
              <span className="font-weight-bold">{campaign.config.radiusPDV} mts</span>
            </div>
            <div className="d-flex">
              <span className="col-6 text-right mr-2 p-0 text-nowrap">
                {i18n.t('audit_config_pdv_dwell_time')}:
              </span>
              <span className="font-weight-bold">
                {campaign.config.dwellTimePDV[0]} {i18n.t('up_to')}{' '}
                {campaign.config.dwellTimePDV[1]} {i18n.t('audit_config_pdv_dwell_time_mins')}
              </span>
            </div>
            <div className="d-flex">
              <span className="col-6 text-right mr-2 p-0 text-nowrap">
                {i18n.t('audit_config_attr_window')}:
              </span>
              <span className="font-weight-bold">
                {campaign.config.attributionWindow} {i18n.t('audit_config_attr_window_days')}
              </span>
            </div>
          </PopoverConfig>
        )}
        <MdDelete
          size="18"
          color="#363537"
          onClick={deleteCampaign}
          style={{ cursor: 'pointer' }}
        />
      </td>
    </tr>
  );
}

function ViewContent({
  totalItems,
  currentPage,
  setPage,
  campaigns,
  user,
  filter,
  handleFilterChange,
  sortBy,
  handleSortBy
}) {
  return (
    <div>
      <div className="row">
        <div className="col-md-7 col-sm-7 col-xs-12">
          <h6 className="mb-4 text-uppercase">{i18n.t('audit_list')}</h6>
        </div>
        <Filter filter={filter} handleFilterChange={handleFilterChange} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              className="mb-2"
              style={{ width: '25%', minWidth: '25%' }}
              onClick={handleSortBy.bind(this, 'campaignName')}
            >
              {i18n.t('campaign_name')}
              <SortIcon sortBy={sortBy} field="campaignName" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'ooh.label')}
            >
              {i18n.t('audit_ooh')}
              <SortIcon sortBy={sortBy} field="ooh.label" />
            </th>
            <th
              className="mb-2"
              style={{ width: '10%', minWidth: '10%' }}
              onClick={handleSortBy.bind(this, 'pdv.label')}
            >
              {i18n.t('audit_pdv')}
              <SortIcon sortBy={sortBy} field="pdv.label" />
            </th>
            <th
              className="mb-2 text-center"
              style={{ width: '10%', minWidth: '10%' }}
              onClick={handleSortBy.bind(this, 'status')}
            >
              {i18n.t('audit_status')}
              <SortIcon sortBy={sortBy} field="status" />
            </th>
            <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'createdAt')}
            >
              {i18n.t('audit_creation_date')}
              <SortIcon sortBy={sortBy} field="createdAt" />
            </th>
            <th
              className="mb-2"
              style={{ width: '20%', minWidth: '20%' }}
              onClick={handleSortBy.bind(this, 'user.email')}
            >
              {i18n.t('audit_created_by')}
              <SortIcon sortBy={sortBy} field="user.email" />
            </th>
            <th className="mb-4" style={{ textAlign: 'center', width: '15%', minWidth: '15%' }}>
              {i18n.t('audit_actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {campaigns &&
            campaigns.map((campaign, i) => <CampaignRow key={i} campaign={campaign} user={user} />)}
        </tbody>
      </table>
      <Paginator totalItems={totalItems} currentPage={currentPage} setPage={setPage} />
    </div>
  );
}

export default function AuditManager(props) {
  const {
    match: {
      params: { page = 1 }
    },
    history,
    user
  } = props;

  const [filter, setFilter] = useState('');

  const [sortBy, setSortBy] = useState({ createdAt: -1 });

  // const responseAuditData = useAPIDataLoader('audit.getAll', {}, { cache: false });
  // if (responseAuditData.error) {
  //   console.log('responseAuditData error', responseAuditData.error);
  // }

  // const { campaigns = [], count } = responseAuditData.hasValue('campaigns')
  //   ? responseAuditData.data.value.campaigns
  //   : {};
  const campaignsResponse = useAPIv2DataLoader(
    'audit',
    `campaigns`,
    { page, filter, sortBy },

    { cache: false, responseHolder: `response` }
  );

  if (campaignsResponse.error) {
    console.log('error', campaignsResponse.error);
  }

  const setPage = page => {
    history.push(`/audit/${page}`);
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const handleSortBy = field => {
    const currentSortBy = sortBy || {};

    const _sortBy = currentSortBy[field]
      ? {
          [field]: currentSortBy[field] * -1
        }
      : {
          [field]: 1
        };

    setSortBy(_sortBy);
  };

  const { campaigns, count } = campaignsResponse.hasValue('response')
    ? campaignsResponse.data.value.response
    : {};

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('audit_title')}</h1>
          <p className="mb-0">{i18n.t('audit_subtitle')}</p>
        </header>
      </div>
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <Loading loaded={campaignsResponse.data.state !== 'LOADING'} />
            <ViewContent
              totalItems={count}
              currentPage={page}
              setPage={setPage}
              campaigns={campaigns}
              user={user}
              filter={filter}
              handleFilterChange={handleFilterChange}
              sortBy={sortBy}
              handleSortBy={handleSortBy}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

const defaultStyle = { float: 'right', marginTop: '-3px', marginRight: '-3px' };

function PopoverConfig({ title, children, style = defaultStyle }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const id = title.replace(/ /gi, '_');

  const toggle = () => {
    setPopoverOpen(po => !po);
  };

  return (
    <span style={style}>
      <MdOutlineTopic
        size="18"
        // color="#FFF"
        id={id}
        className="mr-2"
        onClick={toggle}
        style={{ cursor: 'pointer' }}
      />

      <Popover placement="auto-start" isOpen={popoverOpen} target={id} toggle={toggle}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{children}</PopoverBody>
      </Popover>
    </span>
  );
}
