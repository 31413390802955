export const buildModuleKey = poiType => `planner-${poiType}`;
// TODO: Move local storage utils to a utils out of planner
const instance = `${window._env_.REACT_APP_BRAND}`;

export const saveToLocalStorage = (name, value, module = 'all') => {
  localStorage.setItem(buildKey(module, name), JSON.stringify(value));
};

export const loadFromLocalStorage = (name, module = 'all') => {
  const key = buildKey(module, name);

  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
};

function buildKey(module, name) {
  return `${instance}_${module}_${name}`;
}

const e = {
  saveToLocalStorage,
  loadFromLocalStorage,
  buildModuleKey
};

export default e;
