import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { MdVisibility, MdEdit, MdDelete } from 'react-icons/md/';

import { Card, CardBody } from 'reactstrap';

import numeral from 'numeral';
import moment from 'moment';
import history from '../../../history';

import API from '../../api';
import { useAPICaller } from '../../hooks/api';

import Paginator from '../../components/pagination/Paginator';

import { Loading } from '../../components/utils/Loading';

import { Filter, SortIcon } from '../../components/filter/Filter';
import PopupConfirm from '../../components/utils/PopupConfirm';

function AudienceRow({ audience }) {
  // eslint-disable-next-line no-unused-vars
  const [callResponse, apiCall] = useAPICaller();

  const deleteAudience = () => {
    PopupConfirm({
      onConfirm: () => {
        apiCall('audiences.delete', [audience._id]);
        window.location.reload();
      }
    });
  };

  const detailsAudience = () => {
    history.push(`/planner/details/${[audience._id]}`);
  };

  const editAudience = () => {
    history.push(`/planner/edit/${[audience._id]}`);
  };

  return (
    <tr>
      <td>{audience.name}</td>
      <td>{numeral(audience.data.value.total).format('0.[0] a')}</td>
      <td>{moment(audience.created).format('YY-MM-DD HH:MM')}</td>
      <td>{audience.status}</td>
      <td className="d-flex" style={{ justifyContent: 'space-around' }}>
        <MdVisibility
          size="18"
          color="#363537"
          onClick={detailsAudience}
          style={{ cursor: 'pointer' }}
        />{' '}
        <MdEdit size="18" color="#363537" onClick={editAudience} style={{ cursor: 'pointer' }} />{' '}
        <MdDelete
          size="18"
          color="#363537"
          onClick={deleteAudience}
          style={{ cursor: 'pointer' }}
        />
      </td>
    </tr>
  );
}

function ViewContent({
  totalItems,
  currentPage,
  setPage,
  audiences,
  user,
  filter,
  handleFilterChange,
  sortBy,
  handleSortBy
}) {
  return (
    <div>
      <div className="row">
        <div className="col-md-7 col-sm-7 col-xs-12">
          <h6 className="mb-4 text-uppercase">{i18n.t('audience_list')}</h6>
        </div>
        <Filter filter={filter} handleFilterChange={handleFilterChange} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              className="mb-2"
              style={{ width: '25%', minWidth: '25%' }}
              onClick={handleSortBy.bind(this, 'name')}
            >
              {i18n.t('audience_name')}
              <SortIcon sortBy={sortBy} field="name" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'data.value.total')}
            >
              {i18n.t('audience_size')}
              <SortIcon sortBy={sortBy} field="data.value.total" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'created')}
            >
              {i18n.t('audience_creation_date')}
              <SortIcon sortBy={sortBy} field="created" />
            </th>
            <th
              className="mb-2"
              style={{ width: '15%', minWidth: '15%' }}
              onClick={handleSortBy.bind(this, 'created')}
            >
              {i18n.t('audience_status')}
              <SortIcon sortBy={sortBy} field="status" />
            </th>
            <th className="mb-4" style={{ textAlign: 'center', width: '15%', minWidth: '15%' }}>
              {i18n.t('audience_actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {audiences &&
            audiences.map((audience, i) => <AudienceRow key={i} audience={audience} user={user} />)}
        </tbody>
      </table>
      <Paginator totalItems={totalItems} currentPage={currentPage} setPage={setPage} />
    </div>
  );
}

const defaultResponse = {
  loaded: false,
  data: {
    audiences: [],
    count: 0
  }
};

export default function AudienceManager(props) {
  const {
    match: {
      params: { page = 1 }
    },
    history,
    user
  } = props;

  const [audiencesResponse, setResponse] = useState(defaultResponse);

  const [filter, setFilter] = useState('');

  const [sortBy, setSortBy] = useState({ created: -1 });

  useEffect(() => {
    setResponse(ar => {
      return {
        ...ar,
        loaded: false
      };
    });
    API.noCacheCall('audiences.getAll', { page, filter, sortBy }, (err, response) => {
      setResponse({
        error: err,
        data: response,
        loaded: true
      });
    });
  }, [page, filter, sortBy]);

  if (audiencesResponse.error) {
    console.log('error', audiencesResponse.error);
  }

  const setPage = page => {
    history.push(`/audiences/${page}`);
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const handleSortBy = field => {
    const currentSortBy = sortBy || {};

    const _sortBy = currentSortBy[field]
      ? {
          [field]: currentSortBy[field] * -1
        }
      : {
          [field]: 1
        };

    setSortBy(_sortBy);
  };

  const { audiences, count } = audiencesResponse.data;

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('audience_title')}</h1>
          <p className="mb-0">{i18n.t('planner_manager')}</p>
        </header>
      </div>
      <div className="view-content view-components">
        <Card className="mb-4">
          <CardBody className="table-responsive">
            <Loading loaded={audiencesResponse.loaded} />
            <ViewContent
              totalItems={count}
              currentPage={page}
              setPage={setPage}
              audiences={audiences}
              user={user}
              filter={filter}
              handleFilterChange={handleFilterChange}
              sortBy={sortBy}
              handleSortBy={handleSortBy}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
