import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import AppLayout from './modules/containers/layouts/AppLayout';
import CrudLayout from './modules/containers/layouts/CrudLayout';
import PublicLayout from './modules/containers/layouts/PublicLayout';
import OnboardingLayout from './modules/containers/layouts/OnboardingLayout';
import { getQueryVariable } from './routeUtils';

const defaultPoiType = 'pdv';

export function AppRoute({
  component: Component,
  user,
  navMini = false,
  fullMonth,
  useAggregation,
  filterSinglePOI = false,
  ...rest
}) {
  const poiType = getQueryVariable('type') || defaultPoiType;

  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          return (
            <AppLayout
              user={user}
              navMini={navMini}
              fullMonth={fullMonth}
              useAggregation={useAggregation}
              filterSinglePOI={filterSinglePOI}
              poiType={poiType}
            >
              <Component {...props} user={user} key={props.location.search} poiType={poiType} />
            </AppLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function CRUDRoute({ component: Component, user, navMini = false, fullMonth, ...rest }) {
  const poiType = getQueryVariable('type') || defaultPoiType;
  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          return (
            <CrudLayout user={user} navMini={navMini} poiType={poiType} {...rest}>
              <Component
                {...props}
                user={user}
                fullMonth={fullMonth}
                key={props.location.search}
                poiType={poiType}
              />
            </CrudLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function PublicRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <PublicLayout>
              <Component {...props} />
            </PublicLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function OnboardingRoute({ component: Component, user, loginRequired = false, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (loginRequired && !user) {
          return (
            <Redirect
              to={{
                pathname: '/signup',
                state: { from: props.location }
              }}
            />
          );
        }
        return (
          <OnboardingLayout user={user}>
            <Component {...props} user={user} />
          </OnboardingLayout>
        );
      }}
    />
  );
}
