import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { BarChart, ResponsiveContainer, YAxis, XAxis, Tooltip, Legend, Bar, Cell } from 'recharts';
// import BarChart from '../../../components/charts/BarChart';
import { colors } from '../../../components/charts/Styles';

export default function DwellTimeCard({ responseDwellTime }) {
  if (!responseDwellTime || responseDwellTime.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const auditDwellTime = responseDwellTime.auditDwellTime;

  return (
    <Row>
      <div className="mb-4 col-sm-12 col-md-12">
        <Card>
          <CardBody>
            <CardTitle className="text-uppercase h6">{i18n.t('audit_dwell_time_title')}</CardTitle>
            <div className="small mb-4 card-subtitle">{i18n.t('audit_dwell_time_subtitle')}</div>
            <div style={{ width: '100%', height: '400px' }}>
              <ResponsiveContainer>
                <BarChart data={auditDwellTime}>
                  <YAxis tickFormatter={value => numeral(value).format('0,0')} />
                  <XAxis dataKey="group" />
                  <Tooltip formatter={value => numeral(value).format('0,0')} />
                  <Legend />

                  <Bar
                    dataKey="avg_dwell_time_mins"
                    name={i18n.t('avg_dwell_time_title')}
                    barSize={50}
                  >
                    {auditDwellTime.map((entry, index) => (
                      <Cell fill={colors[index]} key={index} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardBody>
        </Card>
      </div>
    </Row>
  );
}
